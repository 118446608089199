import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { observer } from "mobx-react"

import { deviceStore } from "store/DeviceStore"
import { unitStore } from "store/UnitStore"
import { editDeviceItem, getDeviceInfo } from "api/device"

import {
   Box,
   Heading,
   Form,
   FormField,
   TextInput,
   Button
} from "grommet"

import { Page404 } from "components/Common/Page404"
import { Loader } from "components/Common/Loader"
import { LinkButton } from "components/UI/LinkButton/LinkButton"

import { toast } from "react-toastify"
import { TDevice } from "models/Device"

type TDeviceChangeProps = {
   name          : string,
   remark?       : string,
   alias?        : string,
   isLockFirmware: boolean,
};

export const DeviceEdit = observer (() => {

   const location = useLocation();
   const navigate = useNavigate();

   const { idDevice, idProject } = useParams();

   const [errorFlag     , setErrorFlag     ] = useState <boolean> ();
   const [defaultValues , setDefaultValues ] = useState <TDevice> ();

   const saveChanges = (event: { value: TDeviceChangeProps }) => {

      editDeviceItem ({
         id_device: Number (idDevice),
         name     : event.value.name    ?? defaultValues?.name,
         remark   : event.value?.remark ?? defaultValues?.remark,
      })
      .then (response => {

         if (!response.error_text && defaultValues) {

            deviceStore.getDevices (defaultValues.id_project);
            toast.success ("Изменения сохранены");
            navigate (`/projects/${idProject}/device/${idDevice}`);
         }
         else toast.error (response.error_text);
      });
   };

   useEffect (() => {

      getDeviceInfo (Number(idDevice), Number(idProject))
      .then (response => 

            response.error_text 
            ? setErrorFlag (true) 
            : setDefaultValues (response)
      )
   }, [idDevice, idProject, location.state]);

   useEffect (() => {
      unitStore.getUnits (Number (idDevice))
   }, [idDevice])

   //console.log(isLockFirmware)

   return (
      <Box
         pad    = "small"
         margin = "medium"
      >

         { defaultValues 
           ? (
            <>
               <Heading>
                  Изменить устройство: { defaultValues.name }
               </Heading>

               <Box>
                  <Form
                     onSubmit = { saveChanges }
                     style    = { { width: 'auto' } }
                  >

                     <FormField
                        name  = "name"
                        label = "Название"
                     >
                        <TextInput
                           name         = "name"
                           defaultValue = { defaultValues?.name }
                           required
                        />
                     </FormField>

                     <FormField
                        name  = "remark"
                        label = "Описание"
                     >
                        <TextInput
                           name         = "remark"
                           defaultValue = { defaultValues?.remark }
                        />
                     </FormField>

                     <Box
                        fill = 'horizontal'
                        direction = "row-responsive"
                        gap       = "small"
                     >
                        <Button
                           primary
                           type  = "submit"
                           label = "Сохранить"
                        />
                        <LinkButton to = { `/projects/${idProject}/device/${idDevice}` }>
                           <Button label = "Отмена" />
                        </LinkButton>
                     </Box>

                  </Form>
               </Box>
            </>
            ) 
            : errorFlag 
               ? <Page404 />
               : <Loader />
         }
      </Box>
   );
})
