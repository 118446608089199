import React from 'react';

import { Box, Heading, Layer } from 'grommet';
import { TModal } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';

type TModalProps = {
   modal   : TModal;
   setModal: React.Dispatch<React.SetStateAction<TModal>>;
   heading : string;
   children: JSX.Element;
};

export const Modal = (props: TModalProps) => {

   const { 
      modal, 
      setModal, 
      heading, 
      children 
   } = props

   return (
      <>
         { modal.show && (
            <Layer
               onEsc          = { () => setModal({ ...modal, show: false }) }
               onClickOutside = { () => setModal({ ...modal, show: false }) }
            >
               <Box pad = 'medium'>
                  <Heading
                     margin = { { top: '10px' } }
                     level  = '3'
                  >
                     { heading }
                  </Heading>

                  { children }
               </Box>
            </Layer>
         ) }
      </>
   );
};
