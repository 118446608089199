import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer                        } from 'mobx-react'
import { projectLinkStore                } from 'store/ProjectLinkStore'
import { EmulatorContext, ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard'
import { TProjectLinkItem                } from 'models/ProjectLink'

import {
   Box,
   Button,
   Drop,
   RangeInput,
   Text,
   TextInput
} from 'grommet'
import { Close } from 'grommet-icons'
import './css/PinContextMenu.css'

const CMStyle = { hr: { margin: '1px 0' } }

export const PinContextMenu = observer (({
   targetRef,
   device,
   apin,
   setMenu,
}: {
   targetRef,
   device,
   apin,
   setMenu,
}) => {

   const {
      setPinValues,
      setModal,
   } = useContext (ProjectContext)

   const [pinMenu , setPinMenu ] = useState (false)
   const [linkMenu, setLinkMenu] = useState (false)
   const [pinLink , setPinLink ] = useState <TProjectLinkItem> ()
   const [value   , setValue   ] = useState ('25')

   const emulatorContext = useContext (EmulatorContext)

   const dropRef     = useRef (null)
   const refPinMenu  = useRef (null)
   const refLinkMenu = useRef (null)

   // Hack for outside click on Drop
   /* useEffect(() => {
      const handleOutsideClick = (e) => {
         if (!dropRef?.current?.contains(e.target)) {
            setContextPinMenu(false);
         }
      };

      document.addEventListener('click', handleOutsideClick, false);
      return () => {
         document.removeEventListener('click', handleOutsideClick, false);
      };
   }, []); */


   useEffect (() => {

      const pinLink = projectLinkStore.links
      ?.find (projectLink =>

         projectLink.contacts
         .some (contact => contact.pin == apin.pin.id_unitslotpin)
      )

      if (pinLink) {
         setPinMenu (false)
         setPinLink (pinLink)
      } else setLinkMenu (false)

   }, [apin, projectLinkStore.links])

   const onValue = (value) => {

      if (value != undefined) {
         setPinValues (prev => {

            const newPin = {
               device: device.id_device,
               unit  : apin.nunit,
               pin   : apin.pin.npin,
               value : value,
            }

            return prev.some (valuePin => valuePin.device == device.id_device && 
                                             valuePin.pin == apin.pin.npin)
                      ? prev.map ((valuePin) => (valuePin.pin == apin.pin.npin ? newPin : valuePin))
                      : [...prev, newPin]
         })
      }
   }

   /* useEffect(() => {

         if (reactivePins?.some(reactivePin => reactivePin.device == device.id_device)) {
            setValue(reactivePins?.find(reactivePin => reactivePin.device == device.id_device).value)
         }
      }, [reactivePins]) */

   //console.log(emulatorContext)

   return (
      <>
         <Drop
            elevation = 'medium'
            target    = { targetRef }
            align     = { { top: 'top', left: 'right' } }
            onEsc     = { () => setMenu (false) }
            ref       = { dropRef }
         >
            <Box
               pad       = 'small'
               direction = 'row'
               justify   = 'between'
               width     = { { min: "250px" } }
            >
               <Text>
                  <b>{ ([2, 3, 4].includes(apin.pin.id_pinelectricaltype) &&
                           emulatorContext.isEmulate)
                           ? 'Введите значение' 
                           : apin.pin.name }</b>
               </Text>

               <Button
                  icon    = { <Close size = 'small' /> }
                  onClick = { () => setMenu (false) }
                  style   = { { padding: 0 } }
               />
            </Box>
               
            { (value &&
                  emulatorContext.isEmulate &&
                  [2, 3, 4].includes (apin.pin.id_pinelectricaltype)) && (
                     
               <Box
                  pad         = 'small'
                  onMouseOver = { () => {
                     setLinkMenu (false)
                     setPinMenu  (false)
                  } }
               >
                  <RangeInput
                     min         = '-50'
                     max         = '430'
                     value       = { value }
                     onChange    = { (e) => setValue (e.target.value) }
                  />
                  <TextInput
                     placeholder = 'Введите значение'
                     value       = { value }
                     onChange    = { (e) => setValue (e.target.value) } 
                     type        = 'number'
                  />
                  <Button
                     fill
                     label       = 'Установить'
                     onClick     = { ()  => onValue (value) }
                  />
               </Box>
            ) }

            { pinLink ? (

                  <>
                     <Box
                        className   = 'menuItem'
                        pad         = 'small'
                        ref         = { refLinkMenu }
                        style       = { { cursor: 'pointer' } }
                        onMouseOver = { () => setLinkMenu (true) }
                     >
                        <Text>
                           Связь: <b>{ pinLink.name }</b>
                        </Text>
                     </Box>

                     <hr style = { CMStyle.hr } />

                     <Box className = 'menuItem'>
                        <Button
                           plain
                           label   = 'Удалить из связи'
                           style   = { { padding: '12px' } }
                           onClick = { () => { 

                              projectLinkStore.deletePinFromLink (
                                 pinLink.id_pinlink,
                                 pinLink.name,
                                 device.id_device,
                                 apin.pin.id_unitslotpin
                              )

                              setMenu (false)
                           } }
                           onMouseOver = { () => setLinkMenu (false) }
                        />
                     </Box>
                  </>

               ) : (

                  <Box
                     className   = 'menuItem'
                     pad         = 'small'
                     ref         = { refPinMenu }
                     onMouseOver = { () =>  setPinMenu (true) }
                  >
                     Назначить связь
                  </Box>

               ) }

         </Drop>

         { pinMenu && (

            <Drop
               elevation   = 'medium'
               target      = { refPinMenu.current }
               align       = { { top: 'top', left: 'right' } }
               onMouseOver = { () => setPinMenu (true) }
            >

               <Box
                  className = 'menuItem'
                  flex      = 'grow'
               >
                  <Button
                     plain
                     label   = 'Создать связь'
                     style   = { { padding: '12px' } }
                     onClick = { () => {

                        setModal ({
                           show: true,
                           action: 'addPinLink',
                           link_name: '',
                           id_device: device.id_device,
                           id_unitslotpin: apin.pin.id_unitslotpin,
                        })

                        setMenu (false)
                     } }
                  />
               </Box>

               { projectLinkStore.links && (

                  <>
                     <hr style = { CMStyle.hr } />

                     { projectLinkStore.links.map (projectLink => (

                        <Box
                           className = 'menuItem'
                           key       = { projectLink.id_pinlink }
                           flex      = 'grow'
                        >
                           <Button
                              plain
                              label   = { projectLink.name }
                              style   = { { padding: '12px' } }
                              onClick = { () => {

                                 projectLinkStore.addPinToLink (
                                    projectLink.id_pinlink,
                                    device.id_device,
                                    apin.pin.id_unitslotpin,
                                 )

                                 setMenu (false)
                              } }
                           />
                        </Box>
                     )) }
                  </>
               ) }

            </Drop>
         ) }

         { linkMenu && (

            <Drop
               target      = { refLinkMenu.current }
               elevation   = 'medium'
               align       = { { top: 'top', left: 'right' } }
               onMouseOver = { () => setLinkMenu (true) }
            >
               <Box className = 'menuItem'>
                  <Button
                     plain
                     label   = 'Переименовать связь'
                     style   = { { padding: '12px' } }
                     onClick = { () => {

                        setModal ({
                           show      : true,
                           action    : 'editPinLink',
                           link_name : pinLink.name,
                           id_pinlink: pinLink.id_pinlink,
                        })

                        setMenu (false)
                     } }
                  />
               </Box>
            </Drop>
         ) }

      </>
   )
}
)
