/* eslint-disable */
import React, { useEffect, useRef, useState } from "react"  ;
import { TPathData       } from "../Edge/DeviceEdge";
import { PathContextMenu } from "./PathContextMenu"         ;

type TPathProps = {
   id              ,
   data: TPathData ,
   style           ,
   source          ,
   target          ,
   autoPath: string,
}

export type TCoord = {
   x: number,
   y: number,
}

export const CustomPath = (props: TPathProps) => {
   const { 
      id      ,
      data    ,
      autoPath,
   } = props;

   const [path       , setPath       ] = useState<TCoord[]>([])
   const [mpath      , setMPath      ] = useState<TCoord[]>([])
   const [source     , setSource     ] = useState<TCoord>(props.source)
   const [target     , setTarget     ] = useState<TCoord>(props.target)
   const [customPath , setCustomPath ] = useState<string>();
   const [contextMenu, setContextMenu] = useState<boolean>(false)
   const [position   , setPosition   ] = useState<TCoord>()
   const [pathColor  , setPathColor  ] = useState<string>("black")
   const [pathStroke , setPathStroke ] = useState<string>("4px")
   const [style      , setStyle      ] = useState({ ...props.style, strokeWidth: pathStroke + "!important", stroke: pathColor + "!important" });

   const pathRef         = useRef<SVGPathElement> (null)
   const reactFlowBounds = data?.reactFlowWrapper?.current?.getBoundingClientRect ();
  
   let cut: TCoord[];

   const getPosition = (p: TCoord) => { // Marker position. Might need to be moved

      return data?.reactFlowInstance?.project ({
         x: p.x - reactFlowBounds.left,
         y: p.y - reactFlowBounds.top ,
      });
   }

   const releasePath = (path) => {

      setCustomPath (`M ${source.x}, ${source.y} ` +

                     path.reduce((acc, coord) => {
                        return acc += `L ${coord.x}, ${coord.y} `;
                     }, '') +

                     `L ${target.x}, ${target.y}`)
   }

   const savePath = () => {

      const edges =  data?.reactFlowInstance?.getEdges ();

      if (edges?.length) {

         let edge = edges?.find (edge => edge.id == id);

         edge = {
            ...edge,
            data: { 
               ...edge.data,
               path: path 
            } 
         }

         const eds = edges.filter (edge => edge.id != id)
      
         data?.reactFlowInstance.setEdges ([...eds, edge])
      }
   }  

   const mouseOnVector = (e, coord1, coord2) => {

      const xMin = Math.min (coord1.x, coord2.x);
      const xMax = Math.max (coord1.x, coord2.x);
      const yMin = Math.min (coord1.y, coord2.y);
      const yMax = Math.max (coord1.y, coord2.y);

      const position: TCoord = getPosition ({
         x: e.clientX,
         y: e.clientY,
      });

      //console.log(position)

      return ((xMin <= position.x - 10    ||
               xMin <= position.x         ||
               xMin <= position.x + 10)   && 
              (position.x - 10 <= xMax    ||
               position.x      <= xMax    ||
               position.x + 10 <= xMax )) && 
             ((yMin <= position.y - 10    ||
               yMin <= position.y         ||
               yMin <= position.y + 10)   && 
              (position.y - 10 <= yMax    ||
               position.y      <= yMax    ||
               position.y + 10 <= yMax ))
   }

   const pathMenu = (e) => {

      const onContext = () => setContextMenu (false);
      e.preventDefault ();

      setPosition ({
         x: e.clientX,
         y: e.clientY,
      })

      setContextMenu (true);
      document.addEventListener ("click", onContext);
   }

   const parsePath = () => {

      //console.log("init")
      //console.log(autoPath)

      const arrPath = [...autoPath.matchAll (/L\s*(-?\d+\.?\d+?,\s*-?\d+\.?\d+?)/g)]
      .reduce ((acc: TCoord[], coord) => {

         const coordSplit = coord[1].split(", ");

         acc.push({ 
            x: Number(coordSplit[0]),
            y: Number(coordSplit[1]),
         });

         return acc;
      }, [])

      //console.log(arrPath)

      const optPath = arrPath
      .reduce((acc, coord, i) =>  {

         if ((i != arrPath.length - 1)            &&
             (acc[acc.length - 1]?.x != coord?.x) &&
             (acc[acc.length - 1]?.y != coord?.y)) {
                                        
            acc.push (arrPath[i - 1] || coord);
         }

         return acc;
      }, [])

      setPath (optPath)

      //console.log(optPath)

      if (path.length && source && target && !mpath) releasePath (path)
   }

   const pathMouseOver = (e) => {

      //console.log("mouseOver")

      if (pathRef.current != null) {

         //console.log(pathRef.current);

         // eslint-disable-line
         const arrPath = [...pathRef.current
                          ?.getAttribute("d")
                          ?.matchAll(/L\s*(-?\d+\.?\d+?,\s*-?\d+\.?\d+?)/g)]
                         .reduce((acc: TCoord[], coord) => {

                            const coordSplit = coord[1].split(", ");

                            acc.push({
                              x: Number(coordSplit[0]),
                              y: Number(coordSplit[1]),
                           });
                                                 
                            return acc;
                         }, [])

         //console.log(arrPath)

         const optPath = arrPath
         .reduce((acc: TCoord[], coord: TCoord, i) => {

            if (i == 0 || i == (arrPath.length - 1)) acc.push(coord);

            else if ((arrPath[i - 1]?.x < coord.x && coord.x > arrPath[i + 1]?.x) ||
                     (arrPath[i - 1]?.x > coord.x && coord.x < arrPath[i + 1]?.x) ||
                     (arrPath[i - 1]?.y < coord.y && coord.y > arrPath[i + 1]?.y) ||
                     (arrPath[i - 1]?.y > coord.y && coord.y < arrPath[i + 1]?.y)) {

            }

            else if (acc[acc.length - 1]?.x != coord.x ||
                     acc[acc.length - 1]?.y != coord.y) {

               acc.push (coord);
            }

            return acc;
         }, [])

         //console.log(optPath);

         setPath ([source, ...optPath, target])
      }

      //console.log(path)

      for (const [i] of path.entries()) {

         if (mouseOnVector (e, path[i], path[i + 1])) {

            if (path[i].x == path[i + 1].x) setStyle ({ ...style, cursor: 'ew-resize' });
            if (path[i].y == path[i + 1].y) setStyle ({ ...style, cursor: 'ns-resize' });
            break;
         }
      }
   }

   const pathMouseDown = (e) => {

      for (const [i] of path.entries()) {

         if (mouseOnVector (e, path[i], path[i + 1])) {

            if (path[i].x == path[i + 1].x) cut = path.filter (cutx => cutx.x == path[i].x);
            if (path[i].y == path[i + 1].y) cut = path.filter (cuty => cuty.y == path[i].y);
            //console.log(cut)
            break;
         }
      }

      document.addEventListener ('mousemove', pathMove   );
      document.addEventListener ("mouseup"  , pathMouseUp);
   };

   const pathMouseUp = () => document.removeEventListener('mousemove', pathMove);

   const pathMove = (e) => {
      //const xDiff = (point?.x - e.pageX) / data.reactFlowInstance?.getZoom();

      const fpath   : TCoord[] = [];
      const position: TCoord   = getPosition({
         x: e.clientX,
         y: e.clientY,
      });

      path.forEach(pathPoint => {

         let fl = true;

         cut.forEach (cutPoint => {

            if (JSON.stringify (cutPoint) == JSON.stringify (pathPoint)) {

               if (cut[0]?.x == cut[cut.length - 1]?.x) fpath.push ({
                  x: position.x , 
                  y: pathPoint.y,
               })

               if (cut[0]?.y == cut[cut.length - 1]?.y) fpath.push ({
                  x: pathPoint.x,
                  y: position.y ,
               })
                                                               
               fl = false
            }
         })
      
         if (fl) fpath.push (pathPoint);
      })

      //console.log(fpath)    
      releasePath(fpath)
      setMPath   (fpath)
   };

   useEffect(() => {

      if (data.path?.length && !customPath) {

         setPath     (data.path)
         releasePath (data.path)
      }
      else if (autoPath && !customPath) parsePath ()
   }, [])

   useEffect(() => {

      if (mpath.length) setPath (mpath)
   }, [mpath])  

   useEffect(() => {

      //console.log("target")

      if (path.length && target) {

         if      (path[path.length - 1]?.x == path[path.length - 2]?.x) path[path.length - 1].y = target?.y;
         else if (path[path.length - 1]?.y == path[path.length - 2]?.y) path[path.length - 1].x = target?.x;

         releasePath (path)
      }
   }, [target])

   useEffect(() => {

      //console.log("source")

      if (path.length && source) {

         if      (path[0]?.x == path[1]?.x) path[0].y = source?.y;
         else if (path[0]?.y == path[1]?.y) path[0].x = source?.x;
         else    releasePath (path)
      }
   }, [source])

   useEffect(() => {

      setSource (props?.source)
      setTarget (props?.target)

   }, [props?.source, props?.target])

   useEffect(() => {

      savePath ();
   }, [path])

   //console.log(path)
   //console.log(data?.reactFlowInstance)

   return (
      <>
         { customPath &&
         <>
            <path
               key   = { id         }
               d     = { customPath }
               ref   = { pathRef    }
               className     = 'react-flow__edge-path'
               onMouseDown   = { pathMouseDown }
               onMouseOver   = { pathMouseOver }
               onContextMenu = { pathMenu      } 
               style = { {
                  ...style,
                  stroke     : pathColor ,
                  strokeWidth: pathStroke,
               } }
            />

            { contextMenu && 

               <PathContextMenu
                  targetRef         = { pathRef                 }
                  position          = { position                } 
                  setPathColor      = { setPathColor            }
                  setPathStroke     = { setPathStroke           }
                  markerPosition    = { getPosition             }
                  parsePath         = { parsePath               }
                  reactFlowInstance = { data?.reactFlowInstance }
                  reactFlowWrapper  = { data?.reactFlowWrapper  }
                  path              = { path                    }
                  source            = { source                  }
                  target            = { target                  }
                  id_edge           = { id                      }
               /> 
            }
         </>
         }
      </>
   )
}