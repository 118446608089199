import React, { useState        } from "react"
import { observer               } from "mobx-react"
import { useNavigate, useParams } from "react-router-dom"

import { projectStore } from "store/ProjectsStore"
import { deviceStore  } from "store/DeviceStore"
import { unitStore    } from "store/UnitStore"

import { HeaderSelect } from "./HeaderSelect"

import { Box } from "grommet"

import { STATUS_DELETED } from "constants/globalConstants"
import { Loader } from "components/Common/Loader"

export const MainHeaderSelector = observer (() => {

   const { idProject, idDevice } = useParams()
   const [project, setProject]   = useState<string | undefined>()
   const [device , setDevice ]   = useState<string | undefined>()
   //const [unit   , setUnit   ]   = useState<string | undefined>();

   const navigate = useNavigate ()

   const setDeviceProject = (idProject: string) => {

      setDevice  (undefined)
      setProject (idProject)
      navigate   (`/projects/${idProject}`)
   };

   const goToDevice = (idDevice: string) => {

      setDevice (idDevice)
      if (window.location.href.includes ("code")) navigate (`/projects/${idProject}/device/${idDevice}/code`)
      else                                        navigate (`/projects/${idProject}/device/${idDevice}`)
   }

   /* const goToUnit = (idUnit: string) => {

      setUnit  (idUnit);
      navigate (`/projects/${idProject}/device/${idDevice}/unit/${idUnit}`);
      location.reload ();
   }; */

   //console.log(projectStore.projects);
   //console.log(deviceStore.devices);

   return (
      <Box
         direction = "row-responsive"
         align     = "start"
         margin    = { { horizontal: "20px" } }
      >

         { !!idProject && !projectStore.loading && 

            (projectStore.projects.length > 0
            ? (
               <HeaderSelect
                  placeholder = "Проект"
                  labelKey    = "name"
                  default     = { Number(idProject) }
                  valueKey    = "id_project"
                  options     = { projectStore.projects.filter (project => project.id_state !== STATUS_DELETED) }
                  value       = { project }
                  setState    = { setDeviceProject }
                  getOptions  = { deviceStore.getDevices }
               />
            ) : <Loader size = "medium"/>)

         }

         { !!idProject && !!idDevice && !deviceStore.loading && 
         
            (deviceStore.devices.length > 0
            ? (
               <HeaderSelect
                  placeholder = "Устройство"
                  labelKey    = "name"
                  default     = { Number(idDevice) }
                  valueKey    = "id_device"
                  value       = { device }
                  options     = { deviceStore.devices.filter (device =>  device.id_state !== STATUS_DELETED) }
                  setState    = { goToDevice }
                  getOptions  = { unitStore.getUnits }
               />
            ) : <Loader size = "medium"/>)
         }
        
         { /* {!!idDevice && !Number.isFinite(idDevice) && deviceStore.devices.length > 0 && window.location.href.indexOf("code") == -1 && (
        unitStore.loading 
        ? (<HeaderSelectorLoader />)
        : (<HeaderSelect
            placeholder="Компонент"
            labelKey="name"
            valueKey="id_unit"
            value={unit}
            options={unitStore.units}
            setState={goToUnit}
          />))} */ }

      </Box>
   )
})
