import React from "react"
import { observer } from "mobx-react"
import ReactFlow, { Background, ConnectionMode } from "react-flow-renderer"
import styled from "styled-components"
//import { CustomControls } from "./components/Controls"
//import { Button } from "grommet"
//import { Link } from "grommet-icons"

const ReactFlowStyled = styled(ReactFlow)`

  .react-flow__edges {
      z-index: 1 !important
   }
`

export const Flow = observer ((props: {
   nodes,
   edges?,
   nodeTypes?,
   edgeTypes?,
   onNodesChange,
   onEdgesChange?,
   onNodeDragStop?,
   connectionLineComponent?,
   onInit?
}) => {

   //console.log(props.nodes)

   return (

      <ReactFlowStyled
         nodes                   = { props.nodes }
         edges                   = { props.edges }
         nodeTypes               = { props.nodeTypes }
         edgeTypes               = { props.edgeTypes }
         onNodesChange           = { props.onNodesChange }
         onEdgesChange           = { props.onEdgesChange }
         onNodeDragStop          = { props.onNodeDragStop }
         //snapToGrid              = { true }
         snapGrid                = { [20, 20] }
         defaultZoom             = { 0.5 }
         minZoom                 = { 0.1 }
         maxZoom                 = { 5 }
         connectionLineComponent = { props.connectionLineComponent }
         onInit                  = { props.onInit }
         connectionMode          = { ConnectionMode.Loose }
         //onConnect={props.onConnect}
         fitView                 = { true }
      >

         { /* props.edgeControl
              ? <Button
                 primary
                 icon={<Link />}
                 onClick={() => props.setEdgeControl(!props.edgeControl)} 
                 tip="Ручное управление связями"
                 size="small"
                 style={{ position: 'absolute',
                          top: "10px",
                          right: "20px",
                          zIndex: "5",
                          padding: "6px",
                          borderRadius: "9px",
                          border: "2px solid #005d8f"}}
              />
              : <Button
                 secondary
                 icon={<Link color="#005d8f"/>}
                 onClick={() => props.setEdgeControl(!props.edgeControl)} 
                 tip="Ручное управление связями"
                 size="small"
                 style={{ position: 'absolute',
                          top: "10px",
                          right: "20px",
                          zIndex: "5",
                          padding: "6px",
                          borderRadius: "9px",
                          border: "2px solid #005d8f"}}
              />
           */ }
         { /* <CustomControls 
                setFullScreen={props.setFullScreen} 
                fullScreen={props.fullScreen}
              />
           */ }
         <Background />
      </ReactFlowStyled>
   )
})