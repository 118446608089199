import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const FiltersContext = createContext()

const FiltersProvider = ({ children }) => {
   // data представляет весь набор результатов
   const [data, setData] = useState([])
   // поля или столбцы в наборе данных
   const [filterAttributes, setFilterAttributes] = useState([])
   // filterAttributes и связанные значения, для которых данные должны быть отфильтрованы
   const [filters, setFilters] = useState({})
   // показать/скрыть слой с доступными фильтрами
   const [filtersLayer, setFiltersLayer] = useState(false)
   // filteredResults — это подмножество данных, которое соответствует критериям фильтров
   const [filteredResults, setFilteredResults] = useState([])
   const [isFiltered, setIsFiltered] = useState(false)
   // любые допустимые характиристики слоя
   const [layerProps, setLayerProps] = useState({ position: 'right' })
   // сохраняет предыдущие значения фильтров, если пользователь не хочет применять измененные фильтры
   const [previousFilters, setPreviousFilters] = useState()
   // атрибут, который однозначно идентифицирует запись в данных
   const [primaryKey, setPrimaryKey] = useState([])
   // значение, введенное в поле поиска
   const [searchValue, setSearchValue] = useState('')
   // массив primaryKeys для  filteredResults, которые были выбраны
   const [selected, setSelected] = useState([])

   const value = useMemo(
      () => ({
         data,
         setData,
         filterAttributes,
         setFilterAttributes,
         filters,
         setFilters,
         filtersLayer,
         setFiltersLayer,
         filteredResults,
         setFilteredResults,
         isFiltered,
         setIsFiltered,
         layerProps,
         setLayerProps,
         previousFilters,
         setPreviousFilters,
         primaryKey,
         setPrimaryKey,
         searchValue,
         setSearchValue,
         selected,
         setSelected,
      }),
      [
         data,
         filterAttributes,
         filteredResults,
         filters,
         filtersLayer,
         isFiltered,
         layerProps,
         previousFilters,
         primaryKey,
         searchValue,
         selected,
      ],
   )

   return <FiltersContext.Provider value = { value }>{ children }</FiltersContext.Provider>
}

const useFilters = () => {

   const context = useContext(FiltersContext);
   const {
      data,
      filteredResults,
      setFilteredResults,
      filters,
      setFilters,
      filtersLayer,
      primaryKey,
      setIsFiltered,
      searchValue,
      selected,
      setSelected,
   } = context;

   if (context === undefined) {
      throw new Error('useFilters must be used within a FiltersProvider');
   }

   // Возвращает значение для многоуровневого ключа, например, "property.sub_property"
   const getKeyValue = (item, key) => {

      const keys = key.split(/\./)
      let value  = item
      let i
      let len

      for (i = 0, len = keys.length; i < len; i += 1) {

         const k = keys[i];
         value   = value ? value[k] : null;
      }
      return value;
   };

   // Рекурсивно извлекает все текстовые значения из объекта JSON и возвращает одной строкой.
   const getTextFromJson = json => {
      const obj = {};

      if (json === null || 
          json === undefined) return '';

      if (typeof json === 'string') return json;

      Object.keys(json).forEach(

         key => (obj[key] = getTextFromJson (json[key]))
      );

      return Object.values (obj).join (' ');
   };

   const getFilteredResults = (array, criteria, searchTerm) => {

      let filterResults;
      const filterKeys = Object.keys(criteria);

      if (!array.error_text) {

         filterResults = array?.filter (item =>

            filterKeys.every(key => {

               const value = getKeyValue(item, key);

               if (criteria[key].func) return criteria[key].func(value);

               // также возвращать элементы, если ключ критерия не имеет выбранных значений
               if (criteria[key].length === 0) return true;

               return criteria[key].includes(value);
            }),
         )
      }

      if (searchTerm) {

         const searchString = searchTerm.toLowerCase();

         filterResults = filterResults.filter(
            item => getTextFromJson(item).toLowerCase().indexOf(searchString) > -1,
         );
      }

      return filterResults;
   };

   const getIntersection = (array1, array2) => {

      const results = [];

      array1.forEach(i => {

         let inBoth;

         array2.forEach(j => {
            if (i === j[primaryKey]) inBoth = true;
         });

         if (inBoth) results.push(i);
      });

      return results;
   };

   const applyFilters = (array, criteria, searchTerm) => {

      const filterResults = getFilteredResults(array, criteria, searchTerm);

      const filtersApplied = Object.keys(criteria)
      .map(key => criteria[key].length > 0)
      .includes(true);

      setFilters(criteria);

      setIsFiltered(!(array.length === filterResults.length) ||
                    filtersApplied                           ||
                    (searchTerm && searchTerm.length > 0),);

      setFilteredResults(filterResults);

      // выбранные результаты должны включать только выборки, которые все еще существуют в filterResults
      setSelected (getIntersection (selected, filterResults));
   };

   // Получите доступные значения для каждого поля в наборе данных, чтобы использовать их в качестве возможных параметров фильтрации.
   const getFilterOptions = (dataSet, field) => {

      const options = [];
      const parts   = field.split ('.');

      dataSet.forEach(datum => {

         if (!(parts.length === 1)) {

            const nextField = parts.slice (1, parts.length).join ('.');
            const value     = getFilterOptions ([datum[parts[0]]], nextField)[0];

            // если данные boolean, false включаем
            if ((value || value === false) &&
                 !options.includes(value)) options.push(value);
         }

         return ((datum[field] || datum[field] === false) &&
                  !options.includes (datum[field])         &&
                  options.push (datum[field]));
      });

      return options;
   };

   // поддерживает актуальность filteredResults, так как родительский набор данных может измениться
   const syncFilteredResults = () => {

      if (filteredResults && filteredResults.length === 0 &&
          Object.keys(filters).length === 0 &&
          searchValue.length === 0) setFilteredResults (data);

      // Если набор данных изменился, повторно применяем фильтры. Однако, если слой фильтров открыт,
      // то не применяем их автоматически; пользователь может сам использовать элементы управления слоя
      // для установки отфильтрованных результатов

      else if (!filtersLayer) {

         const nextResults = getFilteredResults (data, filters, searchValue);

         if (JSON.stringify(nextResults) !== JSON.stringify(filteredResults)) setFilteredResults(nextResults);
      }
   };

   return {
      ...context,
      applyFilters,
      getFilteredResults,
      getFilterOptions,
      syncFilteredResults,
   };
};

FiltersProvider.propTypes = {
   children: PropTypes.node,
};

export { FiltersProvider, useFilters };