import React from "react"
import { TDevice } from "models/Device"
import { STATUS_DELETED } from "constants/globalConstants"

import { Card, CardFooter, CardHeader, CardBody, Text } from "grommet"
import { LinkButton } from "components/UI/LinkButton/LinkButton"

export function DeviceCard ({ device }: { device: TDevice }) {

   return (
      <>

         { device.id_state !== STATUS_DELETED && (

            <LinkButton to = { `/projects/${device.id_project}/device/${device.id_device}` }>
               <Card
                  key        = { device.id_device }
                  pad        = "medium"
                  gap        = "medium"
                  round      = "xsmall"
                  className  = { device.id_state === STATUS_DELETED
                                 ? "background-card-deleted"
                                 : "" }
                  background = { device.id_state === STATUS_DELETED
                                 ? "backgroundSideBar"
                                 : "baseBackground" }
                  onClick   = { () => false }
                  hoverIndicator = { "backgroundCardHover" }
               >
                  <CardHeader
                     align   = "center"
                     justify = "start"
                  >
                     <Text
                        size   = "medium"
                        weight = "bold"
                     >
                        { device.name }
                     </Text>
                  </CardHeader>

                  <CardBody
                     align   = "start"
                     justify = "start"
                  >
                     <Text
                        size   = "small"
                        weight = "normal"
                     >
                        { device.remark }
                     </Text>
                  </CardBody>

                  <CardFooter
                     align   = "center"
                     justify = "start"
                  >
                     <Text
                        size   = "small"
                        weight = "normal"
                     >
                        { new Date(device.dset).toLocaleString() }
                     </Text>
                  </CardFooter>
               </Card>
            </LinkButton>
         ) }
      </>
   );
}
