import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { StyledComponent } from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Button, ResponsiveContext, TextInput, TextInputProps } from 'grommet';
import { Search } from 'grommet-icons';
import { useFilters } from './FiltersContext';

const StyledTextInput = styled(TextInput).attrs(() => ({
   'aria-labelledby': 'search-icon',
}))``;

export const SearchFilter = ({ placeholder }) => {
   const size = useContext(ResponsiveContext);
   const filterContext = useFilters();
   const { applyFilters, data, filters, searchValue, setSearchValue } =
    filterContext;
   const [searchFocused, setSearchFocused] = useState(false);
   const inputRef: any = useRef();

   // переносит фокус с кнопки поиска на ввод текста поиска
   useEffect(() => {
      if (searchFocused && inputRef.current) {
         inputRef.current.focus();
      }
   }, [searchFocused, setSearchFocused]);

   return !['xsmall', 'small'].includes(size) || searchFocused ? (
    <Box
       width = "medium"
       flex = "shrink">
       <TextInput
          ref = { inputRef }
          type = "search"
          icon = { <Search id = "search-icon" /> }
          placeholder = { placeholder || 'Поиск' }
          onBlur = { () => setSearchFocused(false) }
          value = { searchValue }
          onChange = { event => {
             setSearchValue(event.target.value);
             applyFilters(data, filters, event.target.value);
          } }
       />
    </Box>
  ) : (
    <Button
       id = "search-button"
       kind = "toolbar"
       icon = { <Search /> }
       onClick = { () => setSearchFocused(true) }
    />
  );
};

SearchFilter.propTypes = {
   placeholder: PropTypes.string,
};