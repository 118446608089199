import React, { useContext } from 'react'
import { observer      } from 'mobx-react'
import { useParams     } from 'react-router-dom'

import { getDeviceItem } from 'api/device'
import { getUnitItem   } from 'api/unit'
import {
   changeDeviceSoftware,
   changeUnitSoftware,
   compileDeviceSoftware,
   compileUnitSoftware,
   createDeviceSoftware,
   createUnitSoftware
} from 'api/softwarecode'

import { TEditorContext } from 'models/Editor'
import { EditorContext, EditorContextProvider } from './EditorContextProvider'

import { Box             } from 'grommet'
import { EditorPano      } from './EditorPano'
import { EditorPanel     } from './components/EditorPanel'
import { EmulateLoader   } from './components/EmulateLoader'
import { LogPanel        } from './components/LogPanel'
import { PortPanel       } from './components/PortPanel'
import { DiffEditorPanel } from './components/DiffEditorPanel'

type TEditorEnvironmentProps = {
   infoLoading : boolean,
   panelHeight : TEditorContext['panelHeight'],
   emulate     : TEditorContext['emulate'],
   log         : TEditorContext['log'],
   logTime     : TEditorContext['logTime'],
   history     : TEditorContext['history'],
   inPorts     : TEditorContext['inPorts'],
   outPorts    : TEditorContext['outPorts'],
}

const EditorEnvironment = observer (({ IdSource }: {IdSource: number}) => {

   const {
      infoLoading,
      panelHeight,
      emulate,
      log,
      logTime,
      history,
      inPorts,
      outPorts,
   } : TEditorEnvironmentProps = useContext (EditorContext)

   return (
      <>

         { emulate && <EmulateLoader device = { IdSource } /> }

         <Box
            fill
            direction = 'row'
            overflow  = 'hidden'
            style     = { { height: panelHeight } }
         >
            { !infoLoading &&
            <>
               <PortPanel
                  role  = 'in'
                  ports = { inPorts }
               />

               <Box
                  fill   = 'horizontal'
                  margin = 'medium'
                  border = { true }
                  round  = { { size: 'xsmall', corner: 'top' } }
               >

                  { history
                    ? <DiffEditorPanel />
                    : <EditorPanel     />
                  }
 
                  <EditorPano />

                  { /* modal?.show && (
                     <EditorModal
                        modal    = { modal }
                        setModal = { setModal }
                        action   = { saveConfig }
                     />
                  )  */ }

               </Box>

               <PortPanel
                  role  = 'out'
                  ports = { outPorts }
               />
            </>
            }
         </Box>

         { log && 
            <LogPanel
               response = { log }
               logTime  = { logTime } 
            /> 
         }
      </>
   )
})

export const DeviceEditorProvider = observer (() => {

   const { idDevice } = useParams ()

   return (
      <EditorContextProvider
         idSource              = { Number (idDevice) }
         getSourceInfo         = { getDeviceItem }
         changeSourceSoftware  = { changeDeviceSoftware }
         compileSourceSoftware = { compileDeviceSoftware }
         createSourceSoftware  = { createDeviceSoftware }
      >
         <EditorEnvironment
            IdSource = { Number (idDevice) }
         />
      </EditorContextProvider>
   )
})

export const UnitEditorProvider = observer (() => {

   const { idUnit } = useParams ()

   return (
      <EditorContextProvider
         idSource              = { Number (idUnit) }
         getSourceInfo         = { getUnitItem }
         changeSourceSoftware  = { changeUnitSoftware }
         compileSourceSoftware = { compileUnitSoftware }
         createSourceSoftware  = { createUnitSoftware }
      >
         <EditorEnvironment
            IdSource = { Number (idUnit) }
         />
      </EditorContextProvider>
   )
})