import React, {
   useState,
   useEffect,
   useContext,
   CSSProperties,
} from 'react'
import { observer       } from 'mobx-react'

import { TPinNode       } from 'models/Pin'
import { ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard'
import { Position       } from "react-flow-renderer"

import { Pin            } from './components/Pin'
import { Button         } from './components/Button'
import { Led            } from './components/Led'
import { Screen         } from './components/Screen'

export const HOCPin = observer(({
   pin,
   position,
   coord,
   sice,
   device,
   slotType,
} : {
   pin: TPinNode,
   position?: Position,
   coord?,
   sice?: number,
   device,
   slotType?,
}) => {

   let id_schematictype = 1
   const schematic: {idSchematic: number} = useContext (ProjectContext)
   if (schematic) id_schematictype = schematic.idSchematic

   const [style, setStyle] = useState <CSSProperties>({});

   useEffect(() => {

      if (coord) {

         setStyle({
            ...style,
            transform: "translate(-50%, -50%)",
            left     : coord.x,
            top      : coord.y
         })
      }
   }, [position, pin]);

   switch (id_schematictype) {

      case 1:

         if (style.top || style.bottom) {

            switch (slotType) {

               case 27:
                  return (
                     <Button
                        position = { position }
                        device   = { device   }
                        pin      = { pin      }
                        style    = { style    }
                     />
                  )

               case 28:
                  return (
                     <Led
                        position = { position }
                        device   = { device   }
                        pin      = { pin      }
                        style    = { style    }
                     />
                  )

               case 29:
                  return (
                     <Screen
                        device   = { device   }
                        pin      = { pin      }
                        style    = { style    }
                     />
                  )

               case 32:
               case 36:

                  switch (pin.pin.id_pintype) {

                     case 27:
                        //console.log('Button')
                        return (
                           <Button
                              position = { position }
                              device   = { device   }
                              pin      = { pin      }
                              style    = { style    }
                           />
                        )

                     case 28:
                        //console.log('LED')
                        return (
                           <Led
                              position = { position }
                              device   = { device   }
                              pin      = { pin      }
                              style    = { style    }
                           />
                        )

                     case 29:
                        //console.log('Screen')
                        return (
                           <Screen
                              device   = { device   }
                              pin      = { pin      }
                              style    = { style    }
                           />
                        )      
                     
                     default:
                        return (
                           <Button
                              position = { position }
                              device   = { device   }
                              pin      = { pin      }
                              style    = { style    }
                           />
                        )
                  }

               default:
                  return (
                     <Pin
                        position = { position }
                        device   = { device   }
                        pin      = { pin      }
                        sice     = { sice     }
                        style    = { style    }
                     />
                  )
            }
         }
         else return <></>

         /* case 2:
            
            return (
               <CircuitPin
                  position = { position }
                  device   = { device   }
                  pin      = { pin      }
                  style    = { style    }
               />
            ) */
   }

});
