import React from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Box, Button, Heading, Layer, Text } from "grommet"
import { deleteProjectItem } from "api/project"
import { projectStore      } from "store/ProjectsStore"
import { STATUS_DELETED    } from "constants/globalConstants"
import { TDeleteLayer      } from "models/Props"

export function ProjectDeleteLayer (props: TDeleteLayer) {

   const navigate = useNavigate ()

   const deleteDevice = () => {

      deleteProjectItem (Number(props.projectId))
      .then(response => {

         if (response.id_state === STATUS_DELETED) {
            projectStore.getProjects()
            toast.success("Проект удален")
            navigate("/projects")
         }
      })
   }

   return (
      <Layer>
         <Box
            pad = "medium"
            gap = "small"
            width = "medium"
            background = "backgroundSideBar"
         >
            <Heading
               level  = { 3 }
               margin = "none"
            >
               Подтверждение
            </Heading>
            <Text>Вы уверены, что хотите удалить проект?</Text>
            <Box
               as        = "footer"
               gap       = "small"
               direction = "row"
               align     = "center"
               justify   = "end"
               pad       = { { top: "medium", bottom: "small" } }
            >
               <Button
                  label   = "Закрыть"
                  color   = "dark-3"
                  onClick = { props.closeFunc }
               />
               <Button
                  onClick = { deleteDevice }
                  label   = {
                     <Text color = "white">
                        <strong>Удалить</strong>
                     </Text>
                  }
                  primary
                  color = "status-critical"
               />
            </Box>
         </Box>
      </Layer>
   )
}
