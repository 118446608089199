import React, { useContext, useEffect, useRef } from "react"
import { observer } from "mobx-react"
import { DiffEditor, useMonaco } from "@monaco-editor/react"

import { unilang    } from "./unilang/tokens"
import { themeStore } from "store/ThemeStore"

import { TEditorContext, TEditorHook } from "models/Editor"
import { EditorContext, useEditor    } from "./EditorContextProvider"

import styled from "styled-components"

type TEditorProps = {
   softwareCode      : TEditorContext['softwareCode'],
   historyItem       : TEditorContext['historyItem'],
   readOnly          : TEditorContext['readOnly'],
   openMenu          : TEditorContext['openMenu'],
   insertText?       : TEditorContext['insertText'],
   setInsertText     : TEditorContext['setInsertText'],
   setSoftwareCode   : TEditorContext['setSoftwareCode'],
   validateInPorts   : TEditorContext['validateInPorts'],
   validateOutPorts  : TEditorContext['validateOutPorts'],
   compilationService: TEditorContext['compilationService'],
}

type TEditorMethods = { saveCode: TEditorHook['saveCode'] }

const Monaco = styled (DiffEditor)`
  position: absolute;
  height  : 100%;
  width   : 100%;
`
export const EditorDiff = observer (() => {

   const {
      softwareCode, setSoftwareCode,
      insertText  , setInsertText,
      historyItem,
      readOnly,
      openMenu,
   }: TEditorProps = useContext (EditorContext)

   const { saveCode }: TEditorMethods = useEditor ()

   const editorRef = useRef (null)
   const monaco    = useMonaco ()

   useEffect (() => {

      if (monaco && editorRef.current) {

         const KM = monaco?.KeyMod
         const KC = monaco?.KeyCode
         const editor = editorRef.current

         // Сохранение
         editor?.addCommand (KM.CtrlCmd | KC.KeyS, saveCode )
         editor?.addCommand (KC.F2, saveCode)
      }
   }, [monaco, editorRef.current, softwareCode])

   useEffect (() => {

      if (monaco) {

         monaco?.languages.register ({ id: 'unilang' })
         monaco?.languages.setMonarchTokensProvider ('unilang', unilang)
      }
   }, [monaco])

   useEffect (() => {
      const selection = editorRef.current?.getSelection ()
      const insert = { 
         identifier: { 
            major: 1, 
            minor: 1 
         },
         range: selection,
         text : insertText,
         forceMoveMarkers: true
      }
      editorRef.current?.executeEdits ("my-source", [insert])
      editorRef.current?.focus ()
      setInsertText ("")
   }, [insertText])

   useEffect (() => {
      if (openMenu) {
         editorRef.current?.focus();
         editorRef.current?.trigger("", "editor.action.quickCommand", "")
      }
   }, [openMenu])

   const handleEditorMount = (editor) => {

      editorRef.current = editor
      editor.focus ()

      const modifiedEditor = editor.getModifiedEditor()
      modifiedEditor.onDidChangeModelContent (() => {
         setSoftwareCode (modifiedEditor.getValue ())
      })
   }

   return (
      <Monaco
         original = { historyItem?.softwarecode }
         modified = { softwareCode }
         language = "unilang"
         options  = { {
            fontSize         : 16,
            automaticLayout  : true,
            readOnly         : readOnly,
            renderWhitespace : 'boundary',
         } }
         theme    = { themeStore.themeMode === "dark" ? "vs-dark" : "vs-light" }
         onMount  = { handleEditorMount }
      />
   )
})
