import React, { useEffect, useState } from "react";
import { deleteDeviceLinkItem } from "api/devicelink";
import { observer } from "mobx-react";
import { TSlot } from "models/Slot";
import { toast } from "react-toastify";
import { deviceLinkStore } from "store/DeviceLinkStore";
import { slotStore } from "store/SlotStore";
import { unitStore } from "store/UnitStore";
import { Box, Button, Heading, Layer } from "grommet";
import { deleteProjectLinkPin, getConflictProjectLink } from "api/projectlink";
import { TConflictLink, TConflictLinkItem } from "models/ProjectLink";

export const LinkDelete = observer ((props: { 
   
   slot?: TSlot,
   idUnit: number,
   setShow

}) => {

   const [conflictLinks, setConflictLinks] = useState <TConflictLink>();

   const deleteLink = async () => {

      if (conflictLinks) {

         conflictLinks.links.forEach ((link: TConflictLinkItem) => {
            
            deleteProjectLinkPin ({
               id_pinlink    : link.id_pinlink,
               id_unitslotpin: link.id_unitslotpin
            })
         });

      }
      
      const response = await deleteDeviceLinkItem (props.slot.id_slotlink)
   
      if (!response.error_text) {
   
         await deviceLinkStore.getDeviceLinks (unitStore.units.find (unit => unit.id_unit == props.idUnit).id_device)
         await slotStore.getAllSlots (unitStore.units)
         .then(() => slotStore.loading = false)
         toast.success (`Связь ${response.id_slotlink ?? ''} удалена`);
      } 
      else toast.error (response.error_text);

      props.setShow (false)
   }

   useEffect(() => {

      const getConflictLink = async (idSlot, idUnit) => {

         const response = await getConflictProjectLink (idSlot, idUnit)
         setConflictLinks(response)
      }

      if (props.slot.id_slotlink && unitStore.units.find (unit => unit.id_unit == props.idUnit).isinternal) {

         let linkedSlot: TSlot;
         const units = Object.values(slotStore.slots)
         
         for (let i = 0; i < units.length; i++) {

            linkedSlot = units[i].find (slot => slot.id_slotlink == props.slot.id_slotlink && slot.id_unitslot != props.slot.id_unitslot)
            if (linkedSlot) break;
         }

         getConflictLink (linkedSlot.id_unitslot, linkedSlot.id_unit)
      }
      else getConflictLink (props.slot.id_unitslot, props.idUnit)
      
   }, [])

   console.log(conflictLinks);
   console.log(props.slot)

   return (
      <Layer
         onEsc          = { () => props.setShow (false) }
         onClickOutside = { () => props.setShow (false) }
      >
         <Box
            fill
            pad = "medium"
         >
            <Heading 
               level     = '3'
               textAlign = 'center'
            >
               Удалить связь
            </Heading>

            { (conflictLinks && conflictLinks.links?.length > 0) && 
               <Box>
                  Будут удалены следующие связи устройства:
                  <ul>

                     { conflictLinks.links?.map (link => (

                        <li key = { link.id_pinlink }>{ link.name }</li>
                     )) }

                  </ul>
               </Box>
            }

            <Box
               pad       = "small"
               direction = "row"
            >
               <Button
                  primary
                  label = "Удалить"
                  fill  = 'horizontal'
                  onClick = { deleteLink }
               />
               <Button
                  label   = "Отмена"
                  fill    = 'horizontal'
                  margin  = { { left: "medium" } }
                  onClick = { () => props.setShow (false) }
               />
            </Box>

         </Box>
      </Layer>
   )
})