import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react'

import { observer        } from 'mobx-react'
import { Tip             } from 'grommet'
import { Handle          } from 'react-flow-renderer'

import { EmulatorContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard'
import { DEC2RGB         } from './function/HEX2RGB.js'
import { PinContextMenu  } from './PinContextMenu/PinContextMenu'
import { TPinNode        } from 'models/Pin.js'

type TContextPin = {
   unit: number
   pin : number
}

const Style28 = {
   width : 35,
   height: 35,
}

const Style32 = {
   width : 27.1,
   height: 27.1,
}

const Style36 = {
   width : 10,
   height: 10,
}

export const Led = observer ((props: {
   position,
   device,
   pin: TPinNode,
   style
}) => {

   const {
      position,
      device,
      pin,
   } = props

   const { 
      activePins,
      reactivePins,
   } = useContext (EmulatorContext)

   const [pinBackground , setPinBackground ] = useState <string> ('FFFFFF')
   const [style         , setStyle         ] = useState <CSSProperties> ()
   const [menu          , setMenu          ] = useState (false) 
   const [contextPin    , setContextPin    ] = useState <TContextPin> ()

   const pinTargetRef = useRef (null)

   useEffect (() => {

      const aPin = activePins?.find (aPin => aPin?.pin == pin.pin.id_unitslotpin)

      if (aPin) {
         const pinB = DEC2RGB (aPin?.value)
         setPinBackground (pinB)
      }
   }, [pin, activePins, reactivePins])

   useEffect (() => {

      let a = {
         width : 0,
         height: 0,
      }

      let correction = 0

      if (pin.slotType == 36) {a = Style36, correction = 8}
      if (pin.slotType == 32) {a = Style32, correction = 3}
      if (pin.slotType == 28) {a = Style28, correction = 8}

      setStyle ({
         ...props.style,
         ...style,
         ...a,
         border    : '1px solid black',
         top       : Number(props.style?.top) - correction,
         background: `#${pinBackground}`,
      })

   }, [pinBackground, props.style])

   const pinMenu = (e, pin) => {

      e.preventDefault ()
      pinTargetRef.current = e.target

      setContextPin ({
         unit: pin.nunit,
         pin : pin,
      })
      setMenu (true)
   }

   //console.log(pin)

   return (
      <>
         <Tip
            content = { `${pin.pin.typedescription}` 
                           + ((pin.nunit !== undefined) 
                              ? ` - PORT[${pin.nunit}][${pin.nport}]` 
                              : '') }
         >
            <Handle
               id            = { 'pin-' + pin.pin.id_unitslotpin.toString () }
               type          = 'source'
               position      = { position }
               style         = { style }
               onContextMenu = { (e) => pinMenu (e, pin.pin) }
                  
            />
         </Tip>

         { (menu                 &&
            pinTargetRef.current &&
            contextPin)          &&

                 <PinContextMenu
                    targetRef = { pinTargetRef.current }
                    device    = { device }
                    apin      = { pin }
                    setMenu   = { setMenu }
                 />
         }
      </>
   )
})
