import { plcUnit       } from "./plsUnit"
import { screenIonizer } from "./screenIonizer"
import { screenUnit    } from "./screenUnit"

export const selectUnit = (id_unittype: number) => {

   switch (id_unittype) {
      case 46:
      case 59: 
         return screenUnit
      case 64:
         return screenIonizer
      default:
         return plcUnit
   }
}