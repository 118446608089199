import React, { FC } from "react";
import { observer } from "mobx-react";
import { Box, Heading } from "grommet";

export const BillingComponent: FC = observer(() => {
  return (
      <Box pad="large">
        <Heading style={{ margin: "0" }}>Баланс</Heading>
      </Box>
  )  
});
