import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { TUnit } from "models/Unit"
import { TUnitType } from "models/UnitType"

import { getUnitType } from "api/unittype"
import { changeUnit, getUnitItem } from "api/unit"

import { unitStore } from "store/UnitStore"

import {
   Box,
   Heading,
   Form,
   FormField,
   TextInput,
   Button,
   Text
} from "grommet"
import { Page404 } from "../../Common/Page404"
import { Loader } from "../../Common/Loader"
import { toast } from "react-toastify"
import { LinkButton } from "components/UI/LinkButton/LinkButton"

type TUnitChangeProps = {
   id_unit    : number,
   id_unittype: number,
   name       : string,
   remark     : string,
   alias      : string,
};

export function UnitEdit () {

   const navigate = useNavigate()

   const { idDevice, idProject, idUnit } = useParams ()
   const [errorFlag, setErrorFlag      ] = useState <boolean> ()
   const [unit     , setUnit           ] = useState <TUnit> ()
   const [type     , setType           ] = useState <TUnitType> ()

   const saveChanges = (event: { value: TUnitChangeProps }) => {

      changeUnit ({
         id_unit    : Number (idUnit),
         id_unittype: Number (unit?.id_unittype),
         name       : event.value.name,
         remark     : event.value.remark,
         alias      : event.value?.alias,
      })
      .then(response => {

         if (!response.error_text) {

            unitStore.getUnits (Number(idDevice))
            toast.success ("Изменения сохранены")
            navigate (`/projects/${idProject}/device/${idDevice}/unit/${idUnit}`);
         } else toast.error (response.error_text)
      })
   }

   useEffect(() => {

      setErrorFlag (false)

      getUnitItem (Number(idUnit))
      .then(unit => {

         if (unit.error_text) setErrorFlag(true) 
         else {

            setUnit (unit)
            
            getUnitType (unit?.id_unittype)
            .then (type => type.error_text ? setErrorFlag (true) : setType (type))
         }
      })
   }, [idDevice, idProject])

   return (
      <Box
         pad    = "small"
         margin = "medium"
      >

         { unit ? (
            <>
               <Heading>Изменить компонент { unit.name }</Heading>

               <Box
                  pad    = "small"
                  fill   = { false }
                  border = { { color: "brand" } }
                  margin = { { bottom: "20px" } }
               >
                  <Text><b>Тип компонента:</b> { type?.name }</Text>
                  <Text><b>Описание типа компонента:</b> { type?.description }</Text>
               </Box>

               <Box align = "start">
                  <Form onSubmit = { saveChanges }>

                     <FormField
                        name  = "name"
                        label = "Название"
                     >
                        <TextInput
                           name         = "name"
                           defaultValue = { unit.name }
                        />
                     </FormField>

                     <FormField
                        name  = "remark"
                        label = "Описание"
                     >
                        <TextInput
                           name         = "remark"
                           defaultValue = { unit.remark }
                        />
                     </FormField>

                     <FormField
                        name  = "alias"
                        label = "Псевдоним"
                     >
                        <TextInput
                           name         = "alias"
                           defaultValue = { unit?.alias }
                        />
                     </FormField>

                     <Box
                        direction = "row-responsive"
                        gap       = "small"
                     >
                        <Button
                           primary
                           type  = "submit"
                           label = "Сохранить"
                        />
                        <LinkButton
                           to = { `/projects/${idProject}/device/${idDevice}/unit/${idUnit}` }
                        >
                           <Button label = "Отмена" />
                        </LinkButton>
                     </Box>
                  </Form>
               </Box>
            </>
         ) : errorFlag ? (
            <Page404 />
         ) : (
            <Loader />
         ) }
      </Box>
   )
}
