import React, { useEffect } from "react"

import { observer     } from "mobx-react"
import { useParams    } from "react-router-dom"

import { projectStore } from "store/ProjectsStore"

import { Package, Add } from "grommet-icons"
import {
   Box,
   Text,
   Grid,
   Button,
   Heading
} from "grommet"
import { Loader       } from "../Common/Loader"
import { ProjectCard  } from "./ProjectCard"
import { LinkButton   } from "components/UI/LinkButton/LinkButton"

export const ProjectsTable = observer(() => {

   const { idProject, idDevice } = useParams()

   useEffect(() => {

      projectStore.getProjects()
   }, [idProject, idDevice])

   if (projectStore.loading) return <Loader />
   else {

      return projectStore?.projects.length > 0
         ? (
            <Box pad = "large">
               <Box
                  direction = "row"
                  align     = "start"
                  gap       = "small"
                  className = "product-table-nav"
               >
                  <Heading style = { { margin: "0" } }>
                     Проекты
                     <LinkButton
                        to    = { "/projects/new" }
                        state = { { name: "", remark: "" } }
                     >
                        <Button
                           primary
                           icon  = { <Add /> }
                           label = "Создать"
                        />
                     </LinkButton>
                  </Heading>
               </Box>

               <Grid
                  columns   = "medium"
                  gap       = "small"
                  className = { "main" }
               >

                  { projectStore.projects.map((project) => (

                     <ProjectCard
                        project = { project }
                        key     = { project.id_project }
                     />
                  )) }

               </Grid>
            </Box>
         ) : (
            <Box
               align   = "center"
               justify = "center"
               margin  = "xlarge"
            >
               <Package />
               <Text>Нет проектов</Text>
            </Box>
         )
   }
})
