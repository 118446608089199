import React, { createContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { getProjectItem     } from 'api/project'
import { unitTypeStore      } from 'store/UnitTypeStore'
import { projectLinkStore   } from 'store/ProjectLinkStore'

import { useParams          } from 'react-router-dom'
import { useAsyncFn         } from 'utils/hooks/useAsyncFn'
import { useProjectEmulator } from './Hooks/useProjectEmulator'

import { EmulateLoader            } from 'components/Editor/components/EmulateLoader'
import { ProjectArea              } from 'components/Devices/components/ProjectArea'
import { ProjectPanel             } from './Component/ProjectPanel'
import { Paragraph, Main, Heading } from 'grommet'
import { ProjectDetailCardModal   } from './Component/ProjectDetailCardModal'
import { Loader                   } from 'components/Common/Loader'

type ParamTypes = Partial<Record<'idProject', string>>

export type TModal = {
   show           : boolean,
   action?        : string,
   link_name?     : string,
   id_device?     : number,
   id_unitslotpin?: number,
   id_pinlink?    : number,
};

export type TPinValue = {
   device: number,
   unit  : number,
   pin   : number,
   value : number,
};

export const ProjectContext  = createContext (null)
export const EmulatorContext = createContext (null)

export const ProjectDetailCard = observer (() => {

   const { idProject } = useParams <ParamTypes> ()
   const projectState  = useAsyncFn (getProjectItem, Number(idProject))

   const [idSchematic  , setIdSchematic  ] = useState (3)
   const [modal        , setModal        ] = useState <TModal> ()
   const [highlightPins, setHighlightPins] = useState ([])
   const [pinValues    , setPinValues    ] = useState <TPinValue[]> ([])

   // For constructed links
   /* 
   const [saveProjectLink, setSaveProjectLink] = useState<boolean>(false)
   useEffect (() => {

      if (saveProjectLink) {

         addProjectLinkItem({
         id_project: Number(idProject),
   id_schematictype: shemeView,
               links: JSON.stringify(projectLink)
         })
         .then(result => {
         if (result.links) toast.success("Связи сохранены");
         })

         setSaveProjectLink(false)
      }
   }, [saveProjectLink]) */

   useEffect(() => {

      if (idProject && idSchematic) 
         projectLinkStore.getProjectLinks (Number (idProject), idSchematic)

      unitTypeStore.getUnitTypes ()
   }, [idProject, idSchematic])

   const emulatorContext = useProjectEmulator ({
      idProject: Number (idProject),
      pinValues: pinValues,
   })

   const { 
      emulateProject,
      emulateLoading, 
      emulateDevice, 
   } = emulatorContext
   
   const projectContext = {
      idProject,
      idSchematic,
      setIdSchematic,
      pinValues,
      setPinValues,
      modal,
      setModal,
      highlightPins,
      setHighlightPins,
      emulateProject,
   }

   //console.log(emulatorContext.screens)

   if (!idProject) {

      return (
         <Main pad = 'large'>
            <Heading>Ошибка при загрузке проекта</Heading>
            <Paragraph>Неверный идентификатор проекта</Paragraph>
         </Main>
      )
   } else if (!projectState || projectLinkStore.loading) return <Loader />
   else {

      return (
         <Main>

            { emulateLoading &&
               <EmulateLoader device = { Number (emulateDevice) } />
            }

            { projectState && 

               <ProjectContext.Provider value = { projectContext }>
                  <ProjectPanel />

                  <EmulatorContext.Provider value = { emulatorContext }>
                     <ProjectArea />
                  </EmulatorContext.Provider>
               
                  { modal?.show && (
                     <ProjectDetailCardModal />
                  ) }
               </ProjectContext.Provider>
            }

            { projectState == undefined && <Loader /> }
         </Main>
      )
   }
})
