import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { TSlot } from "../../models/Slot";
import { unitStore } from "store/UnitStore";
import { slotStore } from "../../store/SlotStore";
import { deviceLinkStore } from "../../store/DeviceLinkStore";

import { Box, Text, Grid, Heading } from "grommet";
import { Package as IconPackage } from "grommet-icons";

import { SlotCardHorizontal } from "./SlotCardHorizontal";
import { SlotCardVertical } from "./SlotCardVertical";
import { Loader } from "../Common/Loader";



export const SlotsTable = observer((props: { idUnit: number }) => {

   const rightSlot   : TSlot[] = [];
   const leftSlot    : TSlot[] = [];
   const internalSlot: TSlot[] = [];
   const outerSlot   : TSlot[] = [];

   if (slotStore.loading || deviceLinkStore.loading) {
      return <Loader />;
   } else {

      slotStore.slots[props.idUnit]?.forEach((slot) => {
      
         switch (slot?.id_slottype) {
        
            case 1 :
            case 11:
               rightSlot.push(slot);
               break;

            case 2 :
            case 10:
               leftSlot.push (slot);
               break;

            default:
               if (slot?.isinternal) {

                  switch (slot?.isinternal) {

                     case 0:  
                        outerSlot.push(slot);
                        break;

                     default:
                        internalSlot.push(slot);
                  }
               }
         }
      })

      return (
         
         slotStore.slots 
         ? (
            <Box
               pad        = "none"
               gap        = "small"
               background = { "baseBackground" }
            >
               <Grid
                  columns = { ["xxsmall", "xsmall", "xxsmall"] }
                  gap     = "xsmall"
                  style   = { { marginLeft: "-20px" } }
               >
                  <Box gap = "small">

                     { leftSlot?.map ((slot: TSlot) => (

                        <SlotCardVertical
                           key    = { slot.id_unitslot } 
                           slot   = { toJS(slot) }
                           idUnit = { props.idUnit }
                        />
                     )) }

                  </Box>

                  <Box gap = "small">
                     <Grid
                        gap       = "none"
                        className = { "main" }
                     >

                        { internalSlot?.map ((slot: TSlot) => (

                           <SlotCardHorizontal
                              key    = { slot.id_unitslot } 
                              slot   = { toJS(slot) }
                              idUnit = { props.idUnit }
                           />
                        )) }

                     </Grid>
                  </Box>

                  <Box gap = "small">

                     { rightSlot?.map ((slot: TSlot) => (

                        <SlotCardVertical
                           slot   = { toJS(slot) }
                           idUnit = { props.idUnit }
                           key    = { slot.id_unitslot }
                        />
                     )) }

                  </Box>
               </Grid>

               { unitStore.units.find (unit => props.idUnit == unit.id_unit)?.isinternal != 1 &&
                  <Grid >

                     <Box
                        pad        = "small"
                        background = { "backgroundSideBar" }
                     >
                        <Heading
                           level     = "4"
                           textAlign = "center"
                           style     = { { margin: "10px auto" } }
                        >
                           Внешние слоты
                        </Heading>

                        { outerSlot?.map((slot: TSlot) => (

                           <SlotCardHorizontal
                              key     = { slot.id_unitslot }
                              slot    = { toJS(slot) }
                              idUnit  = { props.idUnit }
                              isOuter = { true }
                           />
                        )) }

                     </Box>
                  </Grid> 
               }

            </Box>
         )
            
         : (
            <Box
               align   = "center"
               justify = "center"
               margin  = "xlarge"
            >
               <IconPackage />
               <Text>Нет слотов</Text>
            </Box>
         )
      )
   }
});
