import React, { useState, useEffect, useContext, CSSProperties } from "react";
import { ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';
import { Box, Text } from "grommet";
import { Position } from "react-flow-renderer";

type TStyleProps = {
   link  : CSSProperties,
   name  : CSSProperties,
}

export const PinLink = (props: {pin, pinLink, defaultStyle, position}) => {

   const {
      pin,
      pinLink,
      defaultStyle,
      position
   } = props

   const [style, setStyle] = useState <TStyleProps> ();

   const {
      highlightPins   ,
      setHighlightPins,
   } = useContext (ProjectContext);

   const getHighlightPins = () => {
      if (pinLink) setHighlightPins (pinLink.contacts)
   }

   useEffect(() => {

      switch (position) {
         case Position.Top:

            setStyle({
               link: {
                  ...defaultStyle?.link,
                  left  : defaultStyle?.pin.left - 2,
                  bottom: defaultStyle?.pin.top,
               },
               name: {
                  ...defaultStyle?.name,
                  transform: 'rotate(-90deg) translate(-40px, -12px)',
               },
            })
            break;
               
         case Position.Bottom:

            setStyle({
               link: {
                  ...defaultStyle?.link,
                  left: defaultStyle?.pin.left - 2,
                  top : defaultStyle?.pin.top,
               },
               name: {
                  ...defaultStyle?.name,
                  transform: 'rotate(90deg) translate(20px, -12px)',
               },
            })
            break;

         case Position.Left:

            setStyle({
               link: {
                  ...defaultStyle?.link,
                  left:defaultStyle?.pin.left - 12,
                  top :defaultStyle?.pin.top,
                  transform: 'rotate(90deg)',
                  transformOrigin: 'top'
               },
               name: {
                  ...defaultStyle?.name,
                  transform: 'rotate(-90deg) translate(-40px, -12px)',
               },
            })
            break;

         case Position.Right:

            setStyle({
               link: {
                  ...defaultStyle?.link,
                  left: defaultStyle?.pin.left + 10,
                  top  : defaultStyle?.pin.top,
                  transform: 'rotate(-90deg)',
                  transformOrigin: 'top'
               },
               name: {
                  ...defaultStyle?.name,
                  transform: 'rotate(90deg) translate(20px, -12px)',
               },
            })
            break;
      }
   }, [])

   //console.log (style)

   return (
      <Box
         //style        = { { ...style?.link, left: style?.pin.left, top:  style?.pin.top } }
         style = { style?.link }
         background   = { highlightPins.some ((hpin) => hpin.pin == pin.pin.id_unitslotpin) 
                    ? 'red' 
                    : 'backgroundRevers' }
         onMouseOver  = { getHighlightPins }
         onMouseLeave = { () => setHighlightPins ([]) }
      >
         <Box style = { style?.name }>
            <Text
               color = { highlightPins.some ((hpin) => hpin.pin == pin.pin.id_unitslotpin) 
                   ? 'red' 
                   : 'background' }
            >
               { pinLink.name }
            </Text>

         </Box>
      </Box>
   )
}