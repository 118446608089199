import { TUnitType   } from "models/UnitType"
import { TDeviceItem } from "models/Device"
import { TSlot       } from "models/Slot"
import { api         } from "./client"

export const getUnitTypeList = async (): Promise<TUnitType[]> => {
   return await api.send ("GET", "/device/unit/type/")
}

export const getUnitType = async (
   idUnitType: TUnitType["id_unittype"]
): Promise<TUnitType> => {
   return await api.send ("GET", `/device/unit/type/?id_unittype=${idUnitType}`)
}

export const getAvaliableUnitTypeListFromUnitSlot = async (
   idUnitSlot: TSlot["id_unitslot"]
): Promise<TUnitType[]> => {
   return await api.send ("GET", `/device/unit/type/?id_unitslot=${idUnitSlot}`)
}

export const getAvaliableUnitTypeListFromSlotType = async (
   idSlotType: TSlot["id_slottype"]
): Promise<TUnitType[]> => {
   return await api.send ("GET", `/device/unit/type/?id_slottype=${idSlotType}`)
}

export const getAvaliableUnitTypeListFromDevice = async (
   idDevice: TDeviceItem["id_device"]
): Promise<TUnitType[]> => {
   return await api.send ("GET", `/device/unit/type/?id_device=${idDevice}`)
}