import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, ResponsiveContext } from 'grommet';
import { SearchFilter } from './SearchFilter';
import { ResultsSummary } from './ResultsSummary';
import { Filters } from './Filters'
import { useFilters } from './FiltersContext';

export const FilterControls = ({
   actions,
   configure,
   data,
   filters,
   layerProps,
   primaryKey,
   searchFilter,
}) => {

   const {
      setData,
      setFilterAttributes,
      setLayerProps,
      setPrimaryKey,
      syncFilteredResults,
   } = useFilters ()

   const size = useContext (ResponsiveContext)

   useEffect(() => {

      setData(data);
   }, [data, setData])

   useEffect(() => {

      setFilterAttributes(filters);
   }, [filters, setFilterAttributes])

   useEffect(() => {

      setLayerProps(layerProps);
   }, [layerProps, setLayerProps]);

   useEffect(() => {

      setPrimaryKey(primaryKey);
   }, [primaryKey, setPrimaryKey]);

   // поддерживает актуальность filteredResults, так как родительский набор данных может измениться
   useEffect(() => {

      syncFilteredResults();
   }, [syncFilteredResults]);

   return (
      <Box
         flex = { false }
         fill = "horizontal"
      >
         <Box
            direction = "row"
            align     = "start"
            justify   = "between"
            gap       = "small"
            // поиску есть куда расти на мобильных устройствах
            wrap = { ['xsmall', 'small'].includes(size) }
         >
            <Box
               direction = "row"
               align     = "end"
               gap       = "small"
               margin    = { { bottom: 'xsmall' } }
            >

               { searchFilter && 
                  <SearchFilter placeholder = { searchFilter.placeholder } />
               }
               { configure }
               <Filters />

            </Box>

            { actions }

         </Box>
         <ResultsSummary />
      </Box>
   );
};

FilterControls.propTypes = {
   actions  : PropTypes.element,
   configure: PropTypes.element,
   data     : PropTypes.array,
   filters  : PropTypes.arrayOf (
      PropTypes.shape ({
         property  : PropTypes.string,
         label     : PropTypes.string,
         filterType: PropTypes.oneOf(['CheckBoxGroup', 'RangeSelector']),
         inputProps: PropTypes.object,
      }),
   ),
   layerProps: PropTypes.shape ({
      containerProps: PropTypes.object,
      contentProps  : PropTypes.object,
   }),
   primaryKey  : PropTypes.string,
   searchFilter: PropTypes.oneOfType ([
      PropTypes.bool,
      PropTypes.shape ({
         placeholder: PropTypes.string,
      }),
   ]),
};