import { TDevice } from "models/Device";
import { TExternalUnitsProps } from "./types";

export const plcUnit = (device: TDevice, slotImage, unitImages): TExternalUnitsProps => {

   const pinY       = 38
   const unitStyle  = {}
   const deviceSize = unitImages.reduce((sum, unit) => {
      return (sum += unit.unitWidth);
   }, 0)

   return { pinY, unitStyle, deviceSize }
}