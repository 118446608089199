import React, { useContext, useState } from "react"
import { observer } from "mobx-react"

import { editPortItem, getPortItem } from "api/port"
import { EditableButton } from "components/UI/EditableButton/EditableButton"

import { TInnerPort } from "models/Device"
import { EditorContext } from "components/Editor/EditorContextProvider"

export const PortCard = observer ((props: { 
   port : TInnerPort, 
   name : string, 
   align: { left : "right" } | { right: "left"  }
}) => {

   const {
      setInsertText
   } = useContext (EditorContext)

   const [portName, setPortName] = useState <string>(props.name);

   const saveValue = async ( value ) => {

      await editPortItem (props.port.id_unitport, value != '' ? value : props.name)
      const newPort = await getPortItem (props.port.id_unitport)
      setPortName (newPort.name)
   }

   //console.log(portName)

   return (
      <EditableButton
         label   = { portName ? portName : `PORT[${props.port.nunit}][${props.port.nport}]` }
         tip     = { (portName ? `PORT[${props.port.nunit}][${props.port.nport}] ` : '') + 
                     (portName ? '' : props.port.typename) + 
                     ((props.port.description) ? ` -  ${props.port.description}` : '') }
         style   = { { padding: "4px 4px", width: "150px", margin: "4px 1px" } }
         onClick = { () => setInsertText(`PORT[${props.port.nunit}][${props.port.nport}]`) }
         value     = { portName     }
         saveValue = { saveValue      }
         align = { props.align }
      />
   )
});
