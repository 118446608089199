import {
   TCompilationService,
   TSoftware,
   TSoftwareCompilation,
   TSoftwareFileClean,
   TSoftwareHistory,
} from 'models/SoftwareCode'

import { api } from 'api/client'

// Device
export const getDeviceSoftware = async (
   id_device: string
): Promise<TSoftware> => {
   return await api.send ('GET', `/device/softwarecode/?id_device=${id_device}`)
}

export const createDeviceSoftware = async (
   id_device: number
): Promise<TSoftware> => {
   return await api.send ('POST', '/device/softwarecode/', {
      id_device,
      code     : '',
      id_state : 1,
   })
}

export const changeDeviceSoftware = async (
   id_softwarecode: number | undefined,
   code?          : string | undefined
): Promise<TSoftware> => {
   return await api.send ('PUT', '/device/softwarecode/', {
      id_softwarecode,
      code,
   })
}

export const compileDeviceSoftware = async (
   id_device: number,
   id_softwarecode: number,
   code     : string,
   ports    : string,
   mode     : string,
   isload?  : boolean | false,
   id_compilationservice?: number
): Promise<TSoftwareCompilation> => {
   return await api.send ('POST', '/device/softwarecode/compile/', {
      id_device,
      id_softwarecode,
      code,
      ports,
      mode,
      isload: isload || false,
      id_compilationservice,
   })
}

// Unit
export const getUnitSoftware = async (
   id_unit: string
): Promise<TSoftware> => {
   return await api.send ('GET', `/device/unit/softwarecode/?id_unit=${id_unit}`)
}

export const createUnitSoftware = async (
   id_unit: number
): Promise<TSoftware> => {
   return await api.send ('POST', '/device/unit/softwarecode/', {
      id_unit,
      code     : '',
      id_state : 0,
   })
}

export const changeUnitSoftware = async (
   id_softwarecode: number | undefined,
   code?          : string | undefined
) => {
   return await api.send ('PUT', '/device/unit/softwarecode/', {
      id_softwarecode,
      code,
   })
}

export const compileUnitSoftware = async (
   id_unit: number,
   id_softwarecode: number,
   code   : string,
   ports  : string,
   mode   : string,
   isload?: boolean,
   id_compilationservice?: number
): Promise<TSoftwareCompilation> => {
   return await api.send ('POST', '/device/unit/softwarecode/compile/', {
      id_unit,
      id_softwarecode,
      code,
      ports,
      mode,
      isload: isload || false,
      id_compilationservice,
   })
}

// Other
export const getSoftwareHistory = async (
   id_softwarecode: number
): Promise<TSoftwareHistory[]> => {
   return await api.send ('GET', `/device/unit/softwarecode/history/?id_softwarecode=${id_softwarecode}`)
}

export const getCompilationService = async (): Promise<TCompilationService[]> => {
   return await api.send ('GET', '/device/unit/softwarecode/compilationservice/')
}

export const deleteSoftwareFile = async (
   path : string
): Promise<TSoftwareFileClean> => {
   return await api.send ('DELETE', '/device/softwarecode/clean/', { path })
}

/* export const saveCompilateConfig = async (
   deviceId: number,
   config  : string
): Promise<TDevice> => {
   return await api.send ('PUT', '/device/', {
      id_device: deviceId,
      config   : config,
   })
} */
