import React, { CSSProperties, useState } from "react"
import { useNodes, StepEdge, Position } from "react-flow-renderer"

import {
   getSmartEdge,
   pathfindingJumpPointNoDiagonal,
   svgDrawStraightLinePath
} from '@tisoap/react-flow-smart-edge'

import { Loader } from "components/Common/Loader"
import { CustomPath, TCoord } from "../function/CustomPath"
import "./style/Edge.css"

// props.data.flowInstance?.getZoom();  // получить масштаб React Flow

type TEdgeProps = {
   id            : string,
   source,
   target,
   sourceX       : number,
   sourceY       : number,
   targetX       : number,
   targetY       : number,
   style         : CSSProperties,
   markerEnd     : any,
   markerStart   : any,
   sourcePosition: Position,
   targetPosition: Position,
   data          : TPathData,
}

export type TPathData = {
   reactFlowInstance?,
   reactFlowWrapper?,
   path?: TCoord[]
}

export const DeviceEdge = (props: TEdgeProps) => {

   const { 
      id,
      sourcePosition,
      targetPosition,
      sourceX,
      sourceY,
      targetX,
      targetY,
      data 
   } = props
   const nodes   = useNodes()
   const [style] = useState ({ ...props.style, cursor: 'pointer' })

   const edgeProps = { 
      sourcePosition,
      targetPosition,
      sourceX,
      sourceY,
      targetX,
      targetY,
      nodes,
      options: {
         drawEdge: svgDrawStraightLinePath,
         generatePath: pathfindingJumpPointNoDiagonal,
         nodePadding: 20,
         gridRatio: 5
      }
   }

   const smartEdge = getSmartEdge (edgeProps)

   if (smartEdge === null) return <StepEdge { ...props } />
   if (smartEdge) {

      return (
         <CustomPath
            id       = { id }
            data     = { data } 
            style    = { style }
            autoPath = { smartEdge.svgPathString }
            source   = { {
               x: sourceX,
               y: sourceY
            } }
            target   = { {
               x: targetX,
               y: targetY
            } }
         />
      )

      /* return (
      <>
        {data?.edgeControl
        ? <CustomPath
            data={data} 
            style={style}
            source={{
              x: Math.floor(sourceX / 10) * 10,
              y: Math.floor(sourceY / 10) * 10
            }}
            target={{
              x: Math.floor(targetX / 10) * 10,
              y: Math.floor(targetY / 10) * 10
            }}
            autoPath={smartEdge.svgPathString}
          />
        : <>
            <path
              style={style}
              className='react-flow__edge-path'
              d={smartEdge.svgPathString}
              markerEnd={markerEnd}
              markerStart={markerStart}
            />
            <foreignObject
              width={foreignObjectSize}
              height={foreignObjectSize}
              x={smartEdge.edgeCenterX - foreignObjectSize / 2}
              y={smartEdge.edgeCenterY - foreignObjectSize / 2}
              className="edgebutton-foreignobject"
              requiredExtensions='http://www.w3.org/1999/xhtml'
            >
              <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
              ×
              </button>
            </foreignObject>
          </>
        }
      </>
    ) */
   }
   else return <Loader />
}