import React from "react";
import { observer } from "mobx-react";
import { Box, Heading } from "grommet";

export const MainComponent = observer(() => {
   return (
      <Box pad = "large">
         <Heading style = { { margin: "0" } }>Главная</Heading>
      </Box>
   )  
});