import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"

import { Link, useParams } from "react-router-dom"

import { projectStore } from "store/ProjectsStore"
import { deviceStore  } from "store/DeviceStore"
import { rootStore    } from "store/RootStore"

import { Box, Button, Grid, Avatar, Tip } from "grommet"
import { MainSideBar        } from "./MainSideBar"
import { ThemeSwitchButton  } from "components/UI/ThemeSwitch/ThemeSwitchButton"
import { MainUserSection    } from "./UserSection/MainUserSection"
import { MainHeaderSelector } from "components/UI/Selector/NavigationSelector/MainHeaderSelector"
import { PageTitle          } from "./components/PageTitle"

import { Alert, Menu } from "grommet-icons"
import Logo from "assets/img/logo.png"


export const NavigationList: FC = observer((props) => {

   const { idProject, idDevice } = useParams()
   const [sidebar, setSidebar]   = useState(true)

   useEffect (() => {
      !projectStore?.projects.length && projectStore.getProjects()
      !deviceStore?.devices.length   && idProject && !Number.isFinite(idProject) && deviceStore.getDevices (Number(idProject))
   }, [idProject, idDevice])

   return (
      <Grid
         rows    = { ["auto", "flex", "auto"] }
         columns = { ["auto", "flex"] }
         areas   = { [
            ["header" , "header"],
            ["sidebar", "main"],
            ["footer" , "footer"],
         ] }
         className = { "main-grid" }
      >
         <Box
            gridArea  = "header"
            className = "header"
            direction = "row"
            align     = "left"
            justify   = "between"
            pad       = "none"
            style     = { { borderBottom: "1px solid #005d8f" } }
         >
            <Box
               direction = "row"
               justify   = "start"
               pad       = "none"
               align     = "left"
            >
               <Button 
                  icon    = { <Menu /> } 
                  onClick = { () => setSidebar (!sidebar) }
               />
               { /* props.openMenu ? (
                     <Button
                        tip="Закрыть меню"
                        onClick={() => props.setOpenMenu(false)}
                        icon={<Close />}
                     />
                  ) : (
                     <Button
                        tip="Открыть меню"
                        onClick={() => props.setOpenMenu(true)}
                        icon={<Menu />}
                     />
                  ) */ }
               <Box
                  direction = "row"
                  justify   = "start"
                  pad       = "xsmall"
               >
                  <Link to = "/">
                     <Avatar src = { Logo } />
                  </Link>
               </Box>

               <MainHeaderSelector /> 

               { (idProject && !idDevice) && 
                        <PageTitle /> 
               }

            </Box>

            <Box
               direction = "row"
               justify   = "end"
               pad       = "none"
            >

               { rootStore.currentUserStore.currentUserData?.id_workspace == 2 &&
                        <Box
                           pad       = 'small'
                           alignSelf = "center"
                        >
                           <Tip content = "Внимание! Вы работаете в боевым рабочем пространстве.">
                              <Alert color = "red" />
                           </Tip>
                        </Box>
               }

               <ThemeSwitchButton />
               <MainUserSection />

            </Box>
         </Box>

         { sidebar && <MainSideBar /> }

         <Box
            gridArea = "main"
            pad      = "none"
         >
            { props.children }
         </Box>
      </Grid>
   )
})
