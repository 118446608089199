import { api } from "api/client";
import { TWorkspace } from "models/Workspace";

export const getWorkspaceList = async (): Promise<TWorkspace[]> => {
   return await api.send("GET", '/workspace/');
};

export const getWorkspaceItem = async (
   WorkspaceId: number
): Promise<TWorkspace> => {
   return await api.send("GET", `/workspace/?id_workspace=${WorkspaceId}`);
};