import React from "react"

import { TProjectItem   } from "models/Project"
import { STATUS_DELETED } from "constants/globalConstants"

import { LinkButton     } from "components/UI/LinkButton/LinkButton"
import {
   Card,
   CardFooter,
   CardHeader,
   CardBody,
   Text
} from "grommet"

export const ProjectCard = ({
   project
} : {
   project: TProjectItem
}) => {
   
   return (
      <>
         { project.id_state !== STATUS_DELETED && (

            <LinkButton to = { `/projects/${project.id_project}` }>
               <Card
                  key        = { project.id_project }
                  pad        = "medium"
                  gap        = "medium"
                  round      = "xsmall"
                  className  = { project.id_state === STATUS_DELETED
                                 ? "background-card-deleted"
                                 : "" }
                  background = { project.id_state === STATUS_DELETED
                                 ? "backgroundSideBar"
                                 : "baseBackground" }
                  hoverIndicator = { "backgroundCardHover" }
               >
                  <CardHeader
                     align   = "center"
                     justify = "start"
                  >
                     <Text
                        size   = "medium"
                        weight = "bold"
                     >
                        { project.name }
                     </Text>
                  </CardHeader>

                  <CardBody
                     align   = "start"
                     justify = "start"
                  >
                     <Text
                        size   = "small"
                        weight = "normal"
                     >
                        { project.remark }
                     </Text>
                  </CardBody>

                  <CardFooter
                     align   = "center"
                     justify = "start"
                  >
                     <Text
                        size   = "small"
                        weight = "normal"
                     >
                        { new Date (project.dset).toLocaleString () }
                     </Text>
                  </CardFooter>
               </Card>
            </LinkButton>

         ) }
      </>
   )
}
