import React, { useCallback, useEffect, useState } from "react"
import { observer   } from "mobx-react"
import { useParams  } from "react-router-dom"

import { TUnitType  } from "models/UnitType"
import { TSlot      } from "models/Slot"

import { addNewUnit } from "api/unit"
import {
   getAvaliableUnitTypeListFromDevice,
   getAvaliableUnitTypeListFromSlotType
} from "api/unittype"

import { unitStore  } from "store/UnitStore"
import { slotStore  } from "store/SlotStore"
import { deviceLinkStore } from "store/DeviceLinkStore"

import {
   Box,
   Heading,
   Form,
   FormField,
   TextInput,
   Button,
   Select
} from "grommet"
import { toast } from "react-toastify"


type TCreateUnitEvent = {
   value: {
      id_unittype: number,
      name       : string,
      remark     : string,
      alias      : string,
   }
}

export const UnitCreate = observer ((props: {setShow, slot?: TSlot}) => {
   
   const { idDevice } = useParams ()
   const [avalUnitType, setAval ] = useState <TUnitType[]> ()
   const [value       , setValue] = useState ()
   const [description , setDescription] = useState <string> ()

   const idUnitSlot = props.slot ? props.slot.id_unitslot : null

   const createUnit = useCallback (async (event: TCreateUnitEvent) => {

      if (idDevice) { 

         const response = await addNewUnit ({
            id_unittype: avalUnitType
            ?.find (type => type.name == value)
            ?.id_unittype,
            id_device  : Number (idDevice),
            id_unitslot: idUnitSlot,
            name       : event.value.name,
            remark     : event.value.remark,
            alias      : event.value.alias,
         })

         if (!response.error_text) {
            
            props.setShow (false)

            await unitStore.getUnits (Number (idDevice))
            await slotStore.getDeviceSlots (Number (idDevice))
            await deviceLinkStore.getDeviceLinks (Number (idDevice))

            toast.success (`Компонент ${response.name ?? ""} создан`)
         } else toast.error (response.error_text)

      }
   }, [idDevice, avalUnitType, idUnitSlot])

   useEffect (() => {

      (async () => {
         const response = props.slot 
                          ? await getAvaliableUnitTypeListFromSlotType (Number (props.slot.id_slottype)) 
                          : await getAvaliableUnitTypeListFromDevice (Number (idDevice))

         setAval(response)
      })()

      const desc = avalUnitType
      ?.find (type => type.name == value)
      ?.description

      setDescription (desc)
   }, [value])

   return (
      <Box
         fill
         pad    = "medium"
         margin = "medium"
      >
         <Heading  margin = { { top:"10px" } } >
            Добавить компонент
         </Heading>

         <Form onSubmit = { createUnit } >
            <Box
               width  = "large"
               margin = { { bottom: "large" } }
            >

               { avalUnitType &&

                  <FormField
                     required
                     name    = "type"
                     htmlFor = "type"
                     label   = "Тип компонента"
                  >
                     <Select
                        name        = "type"
                        placeholder = "Выберите тип компонента"
                        options     = { avalUnitType?.map (type => type.name) }
                        value       = { value }
                        onChange    = { ({ option }) => setValue (option) }
                     />
                     { description &&
                        <Box pad = "small">
                           <span style = { { whiteSpace: "pre-line" } }>
                              { description }
                           </span>
                        </Box>
                     }
                  </FormField> 
               }

               <FormField
                  name  = "name"
                  label = "Название"
               >
                  <TextInput name = "name" />
               </FormField>

               <FormField
                  name  = "remark"
                  label = "Описание"
               >
                  <TextInput name = "remark" />
               </FormField>

               <FormField
                  name  = "alias"
                  label = "Псевдоним"
               >
                  <TextInput name = "alias" />
               </FormField>
            </Box>

            <Button
               primary
               type  = "submit"
               label = "Создать" 
            />
            <Button
               label   = "Отмена"
               margin  = { { left: "medium" } }
               onClick = { () => props.setShow (false) }
            />
         </Form>
      </Box>
   )
})
