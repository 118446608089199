import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { rootStore } from "store/RootStore"

import { Box, Button } from "grommet"
import {
   Home        as IconHome,
   AppsRounded as IconProjects,
   Cpu         as IconHardware,
} from "grommet-icons";

import { MainSideBarButton } from "./MainSideBarButton"
import { TModal, WorkspaceModal } from "./components/WorkspaceModal"

export function MainSideBar () {

   const [modal, setModal] = useState<TModal>();
   const navigate = useNavigate();

   return (

      <Box
         gridArea   = "sidebar"
         background = "backgroundSideBar"
         width      = "small"
         animation  = { [
            { 
               type    : "fadeIn",
               duration: 300 
            },
            {
               type    : "slideRight", 
               size    : "medium", 
               duration: 150 
            },
         ] }
      >

         <Box>
            <MainSideBarButton
               icon = { <IconHome /> }
               name = { "Главная" }
               link = { "/" }
            />
         </Box>

         <Box>
            <MainSideBarButton
               icon     = { <IconProjects /> }
               name     = { "Проекты" }
               callback = { () => {
                  navigate("/projects");
                  location.reload();
               } }
               //link={"/projects"}
            />
         </Box>

         <Box>
            <MainSideBarButton
               icon = { <IconHardware /> }
               name = { "Оборудование" }
               link = { "/hardware" }
            />
         </Box>

         <Box
            pad       = "small"
            justify   = "between"
            className = "footer"
            style = { { textAlign: "center" } } 
         >
            { (!!rootStore.currentUserStore.currentUserData?.isadmin) &&
               <Box
                  pad = { { horizontal: "small", vertical: "small" } }
               >
                  <span>Рабочее пространство:</span>
                  <Button 
                     primary
                     label   = { rootStore.currentUserStore.currentUserData?.workspacename }
                     onClick = { () => setModal({ ...modal, show: true }) }
                  />
                  { modal?.show &&
                     <WorkspaceModal
                        modal    = { modal }
                        setModal = { setModal }
                     />
                  }
               </Box>
               
            }
            <Box>
               © { new Date ().getFullYear () } UniPort
            </Box>
         </Box>
      </Box>
   );
}
