import { TPortItem } from "models/Port";
import { api } from "api/client";

export const getPortsList = async (
   unitId: number
): Promise<TPortItem[]> => {
   return await api.send("GET", `/device/unit/port/?id_unit=${unitId}`);
};

export const getPortItem = async (
   portId: number
): Promise<TPortItem> => {
   return await api.send("GET", `/device/unit/port/?id_unitport=${portId}`);
};

export const editPortItem = async (
   unitPortId: number,
   name      : string
): Promise<TPortItem> => {
   return await api.send('PUT', '/device/unit/port/', { 
      id_unitport: unitPortId,
      name       : name 
   });
};