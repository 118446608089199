import { makeAutoObservable } from "mobx"
import { errorHandler } from "../utils/errorHandler"
import { TWorkspace } from "models/Workspace"
import { getWorkspaceList } from "api/workspace"
import { setWorkspace } from "api/auth"
import { ACCESS_TOKEN } from "constants/globalConstants"
import { getLocalStorageItem, setLocalStorageItem } from 'utils/localStorage'
import { rootStore } from "./RootStore"


class WorkspacesStore {

   loading  = false
   workspaces: TWorkspace[] = []

   constructor () {
      makeAutoObservable(this)
   }

   async getWorkspaces () {

      try {
         this.setLoading (true);
         const result = await getWorkspaceList ()
         this.setWorkspaces (result)
      }
      catch (err) { errorHandler (err) }
   }

   setWorkspaces (workspacesArr: TWorkspace[]) {

      this.workspaces = workspacesArr
      this.setLoading (false)
   }

   async changeWorkspace (id_workspace: number) {

      this.setLoading (true)

      return await setWorkspace (id_workspace)
      .then (result => {

         setLocalStorageItem (ACCESS_TOKEN, result.token)

         if (getLocalStorageItem (ACCESS_TOKEN, null)) {
            rootStore.currentUserStore.getCurrentUserData ()

            location.reload ()
         }
      })
      .finally (() => this.setLoading (false))
   }

   setLoading = (isLoading: boolean) => {
      this.loading = isLoading
   }
}

export const workspaceStore = new WorkspacesStore()