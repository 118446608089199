import React from "react"

import { useParams      } from "react-router-dom"
import { useNavigate    } from "react-router-dom"

import { STATUS_DELETED } from "constants/globalConstants"
import { deleteUnit     } from "api/unit"
import { unitStore      } from "store/UnitStore"

import { toast          } from "react-toastify"
import { Box, Layer, Button, Heading, Text } from "grommet"

export function DeleteLayer ({
   closeFunc
} : {
   closeFunc: () => void
}) {

   const {
      idDevice,
      idProject,
      idUnit
   } = useParams ()
   const navigate = useNavigate ()

   const deleteUnitItem = () => {

      deleteUnit (Number (idUnit))
      .then (response => {

         if (response.id_state === STATUS_DELETED) {

            unitStore.getUnits (Number (idDevice))
            toast.success ("Компонент удалён")
            navigate (`/projects/${idProject}/device/${idDevice}`)
         }
      })
   }

   return (

      <Layer>
         <Box
            pad        = "medium"
            gap        = "small"
            width      = "medium"
            background = "backgroundSideBar"
         >
            <Heading
               level  = { 3 }
               margin = "none"
            >
               Подтверждение
            </Heading>

            <Text>Вы уверены, что хотите удалить компонент устройства?</Text>

            <Box
               as        = "footer"
               gap       = "small"
               direction = "row"
               align     = "center"
               justify   = "end"
               pad       = { { top: "medium", bottom: "small" } }
            >
               <Button
                  label   = "Отмена"
                  color   = "dark-3"
                  onClick = { closeFunc }
               />
               <Button
                  primary
                  onClick = { deleteUnitItem }
                  color   = "status-critical"
                  label   = { <Text color = "white">
                     <strong>Удалить</strong>
                  </Text> }
               />
            </Box>
         </Box>
      </Layer>
   )
}
