import { makeAutoObservable } from "mobx"
import { TUnit              } from "models/Unit"
import { getUnitList        } from "api/unit"
import { errorHandler       } from "utils/errorHandler"

class UnitStore {

   loading        = true
   units: TUnit[] = []

   constructor () {
      makeAutoObservable (this)
   }

   setUnits (units: TUnit[]) {

      this.units   = units
      this.loading = false
   }

   getUnits = async (idDevice: number) => {
      
      try {
         this.loading = true
         const result = await getUnitList (idDevice)
         this.setUnits (result)
      } catch (err) {
         errorHandler (err)
      }
   }
}

export const unitStore = new UnitStore ()