import { makeAutoObservable } from "mobx"

import { TSlot        } from "models/Slot"
import { TUnit        } from "models/Unit"
import { errorHandler } from "utils/errorHandler"

import {
   getAvaliableSlotsList,
   getDeviceSlotList,
   getSlotsList
} from "api/slot"

type slots = {
   [index: number]: TSlot[]
}

class SlotStore {
   loading      = true
   slots: slots = {}

   constructor () {
      makeAutoObservable(this)
   }

   setSlots (slots: TSlot[], idUnit: number) {
      this.slots[idUnit] = slots
      this.loading = false
   }

   getSlots = async (idUnit: number) => {
      try {
         const result = await getSlotsList(idUnit)
         this.setSlots (result, idUnit)
      } catch (err) {
         errorHandler (err)
         this.loading = false
      }
   }

   getAvaliableSlots = (units: TUnit[]) => {
      units.forEach (unit => {
         this.getSlots (Number (unit.id_unit))
      })
      this.loading = false
   }

   getAvaliableSlotsForLink = async (idSlot) => {
      const response: TSlot[] = await getAvaliableSlotsList (idSlot)
      return response
   }

   getAllSlots = async (units: TUnit[]) => {
      units?.forEach (async unit => {
      //console.log ("Load slots for unit: " + unit.id_unit)
         await this.getSlots (Number (unit.id_unit))
      })
   }

   getDeviceSlots = async (idDevice: number) => {

      const result = await getDeviceSlotList (idDevice)

      const slots = result.reduce ((acc, slot: TSlot) => {

         if (!acc[slot.id_unit]) acc[slot.id_unit] = []
         acc[slot.id_unit].push (slot)

         return acc
      }, [])

      if (slots.length > 0) {
         slots.forEach ((slots, id_unit) => {
            this.setSlots (slots, id_unit)
         })
      } else this.loading = false
   }
}

export const slotStore = new SlotStore ()
