import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, ThemeContext } from 'grommet';
import { Filter } from 'grommet-icons';

import { useFilters } from './FiltersContext';
import { FiltersLayer } from './FiltersLayer';

export const Filters = () => {

   const {
      applyFilters,
      data,
      isFiltered,
      setIsFiltered,
      filters,
      filtersLayer,
      setFiltersLayer,
      setPreviousFilters,
      setSearchValue,
   } = useFilters();

   const [filterCount, setFilterCount] = useState();
   const theme = useContext(ThemeContext);

   // Обеспечивает индикацию количества применённых фильтров
   useEffect(() => {

      let count = 0;

      // Дождитесь обновления счетчика, пока пользователь не завершит настройку желаемых фильтров
      if (!filtersLayer) {
         Object.entries(filters).forEach(filter => {
            // фильтр может быть либо массивом, либо объектом со свойством значения,
            // проверьте оба варианта
            if (filter[1].length > 0 || (filter[1].value && filter[1].value.length))
               count += 1;
         });
         setFilterCount(count);
      }
   }, [filters, filtersLayer, setFilterCount]);

   return (
      <>
         <Box
            direction  = "row"
            align      = "center"
            gap        = "small"
            flex       = { false }>
            <Button
               kind    = "toolbar"
               badge   = { filterCount > 0
                           ? { background: theme.global.colors.text,
                              value      : filterCount, }
                           : null }
               icon    = { <Filter /> }
               tip     = { isFiltered
                           ? `Открыть фильтр, ${filterCount} фильтров активно`
                           : 'Открыть фильтр' }
               onClick = { () => {
                  setPreviousFilters(filters);
                  setFiltersLayer(true);
               } }
            />

            { isFiltered && (
               <Button
                  label   = "Очистить фильтр"
                  onClick = { () => {
                     const nextFilters = {};
                     applyFilters(data, nextFilters);
                     setIsFiltered(false);
                     setSearchValue('');
                  } }
               />
            ) }

         </Box>
         { filtersLayer && <FiltersLayer /> }
      </>
   );
};