import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TDevice } from 'models/Device';

import { Box, Button, Text } from 'grommet';
import { Code, Edit } from 'grommet-icons';

import { useDeviceNode } from './hooks/useDeviceNode';
import { useDeviceNodeAction } from './hooks/useDeviceNodeAction';
import { HOCPin } from '../Pins/HOCPin';
import { EmulatorContext, ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';

import './css/DeviceNode.css';
import { getTransformedCoords } from '../function/svgUtils';
import { Position } from "react-flow-renderer";


export const DeviceNode = (props: {
   data: {
      device: TDevice,
   }}) => {

   const { device       } = props.data
   const { idSchematic  } = useContext (ProjectContext);
   const { reactivePins } = useContext (EmulatorContext)

   const navigate = useNavigate();

   const [editButton, setEditButton ] = useState<boolean>       (false);
   const exRef                        = useRef ([]);

   /* const [contextDeviceMenu, setContextDeviceMenu] = useState(false);
   const deviceTargetRef = useRef(null);
   const deviceMenu = (e) => {
      if (device.units.some((unit) => unit.isexternal)) {
         e.preventDefault();
         deviceTargetRef.current = e;
         setContextDeviceMenu(true);
      }
   }; */

   const { units, pins } = useDeviceNode (device, idSchematic);

   const [expin, setExpin] = useState ([]);
   
   useDeviceNodeAction (
      exRef,
      units,
      reactivePins,
   )

   useEffect(() => {

      if (exRef.current.length) {

         let expins   = [];
         let sumWidth = 0;

         exRef.current?.forEach ((unitSVG, i) => {

            const circlesSVG = Array.from (unitSVG?.querySelectorAll ('circle'))
            .filter ((circlePin: SVGCircleElement) => /pin_/.test (circlePin.id)) as SVGCircleElement[]

            for (const circleSVG of circlesSVG) {

               const spin = device.units
               .flatMap (unit => unit.slots)
               .flatMap (slot => slot.pins )
               .find    (pin  => pin.id_unitslotpin == Number (circleSVG.id.replace ('pin_', '')))

               if (spin) {

                  const cx = circleSVG.cx?.baseVal.value + sumWidth
                  const cy = circleSVG.cy?.baseVal.value

                  let coord;
                  if (cx && cy) coord = getTransformedCoords (cx, cy, circleSVG.getCTM())

                  if (!(expin.some (pin => pin?.id_unitslotpin == spin?.id_unitslotpin))) {
                  
                     const pos = device.units
                     .flatMap (unit => unit.slots)
                     .find    (slot => slot.pins
                     .some    (pin => pin.id_unitslotpin == spin.id_unitslotpin))
                     .id_slotlocation

                     let position: Position

                     switch (pos) {
                        case 2:
                           position = Position.Left
                           break;
                              
                        case 3:
                           position = Position.Right
                           break;

                        case 4:
                           position = Position.Top
                           break;

                        case 5:
                           position = Position.Bottom
                           break;
                     }

                     const port = device.ports?.find (port => 
                        port.id_unitslotpin == spin.id_unitslotpin
                     )

                     expins = [...expins, {
                        pin: spin,
                        position,
                        coord,
                        slotType: device.units
                        .flatMap (unit => unit.slots)
                        .find    (slot => slot.pins
                        .find    (pin => pin.id_unitslotpin == spin.id_unitslotpin)).id_slottype,
                        nunit   : port?.nunit,
                        nport   : port?.nport,
                     }]

                  }
               }
            }

            sumWidth += Number (units[i].unitWidth)
         })

         setExpin (expins)
      }
   }, [exRef.current.length, units])

   //console.log(expin)

   return (
      <>
         { editButton && 

            <Box
               direction    = 'column'
               fill         = 'vertical'
               justify      = 'between'
               background   = 'backgroundSideBar'
               style = { {
                  position : 'absolute',
                  left     : '-68px',
                  minHeight: '250px',
               } }
               onMouseEnter = { () => setEditButton (true ) }
               onMouseLeave = { () => setEditButton (false) }
            >
               <Text
                  size   = 'large'
                  weight = 'bold'
                  style  = { {
                     padding     : '20px',
                     writingMode : 'vertical-lr',
                     textOverflow: 'ellipsis',
                     whiteSpace  : 'nowrap',
                     overflow    : 'hidden',
                  } }
               >
                  { device.name }
               </Text>

               <Box 
                  direction = 'column'
               >
                  { units?.some ((unit) => unit.unit.id_unittypegroup == 1) &&

                     <Button
                        secondary
                        color   = 'brand'
                        tip     = 'Редактор кода'
                        style   = { { padding: '15px 20px' } }
                        icon    = { <Code /> }
                        onClick = { () => navigate (`/projects/${device.id_project}/device/${device.id_device}/code/`) }
                     />
                  }

                  <Button
                     secondary
                     color = 'brand'
                     tip   = 'Редактировать'
                     style = { { padding: '15px 20px' } }
                     icon  = { <Edit size = 'medium' /> }
                     href  = { `/projects/${device.id_project}/device/${device.id_device}` }
                  />
               </Box>
            </Box>
         }

         <Box
            direction = 'row'
         >

            <Box
               direction    = 'row'
               onMouseEnter = { () => setEditButton (true ) }
               onMouseLeave = { () => setEditButton (false) }
            >

               { units && units.map ((body, i) => (

                  <svg
                     xmlns   = 'http://www.w3.org/2000/svg'
                     key     = { i }
                     width   = { Number (body.unitWidth) / 2 }
                     height  = { Number (body.unitHeight) / 2 || '450' }
                     viewBox = { `0 0 ${Number (body.unitWidth)} ${Number (body.unitHeight) || '900'}` }
                     style   = { body.unitStyle }
                     ref     = { unitSVG => exRef.current[i] = unitSVG }
                     dangerouslySetInnerHTML = { { __html: body.unitBody } }
                     //onContextMenu={(e) => deviceMenu(e)}
                  />
               )) 
               }
            </Box>

            <Box direction = 'row'>

               { pins.map ((pin, i) => (

                  <HOCPin
                     key      = { i + '-' + pin.pin.npin }
                     position = { pin.position }
                     device   = { device }
                     pin      = { pin }
                     slotType = { pin.slotType }
                  />

               )) }

               { expin.length > 0 && expin.map ((pin, i) => {

                  if (pin.pin) { return (

                     <HOCPin
                        key      = { i + '-' + pin.pin?.npin }
                        position = { pin.position }
                        device   = { device }
                        pin      = { pin }
                        coord    = { pin.coord }
                        slotType = { pin.slotType }
                     />

                  ) }
               }) }

            </Box>

            { device.alias &&

               <Box
                  margin = { {
                     horizontal: '5px',
                     top       : '-25px'
                  } }
               >
                  { device.alias }
               </Box>

            }
         </Box>

         { /* {contextDeviceMenu && deviceTargetRef.current && (
            <DeviceContextMenu
               targetRef={deviceTargetRef.current}
               device={toJS(device)}
               setContextDeviceMenu={setContextDeviceMenu}
               externalDevices={externalDevices}
               reactivePins={reactivePins}
            />
         )} */ }
      </>
   );
};
