import React from "react";
import { observer } from "mobx-react";
import { rootStore } from "store/RootStore";

import { Form, FormField, Button, Box, TextInput, MaskedInput, CheckBox } from "grommet";
import { MailOption, Key } from "grommet-icons";
import { HeaderComponent } from "../Common/Header";

type TLoginFormData = {
   email   : string;
   password: string;
}

export const LoginComponent = observer (() => {

   const handleSubmitLoginForm = (formData: TLoginFormData): void => {
      rootStore.authStore.login (formData);
   }

   return (

      <div className = "main-layout">

         <HeaderComponent />

         <div>
            <Box
               fill
               align   = "center"
               justify = "center"
            >
               <Box
                  width  = "medium"
                  margin = "large"
               >
                  <Form
                     className = "form"
                     name      = "basic"
                     onSubmit  = { (event) => {
                        handleSubmitLoginForm (event.value as TLoginFormData);
                     } }
                  >
                     <FormField
                        required
                        label     = "Email"
                        name      = "email"
                        className = "form__input"
                     >
                        <MaskedInput
                           reverse
                           icon = { <MailOption /> }
                           name = "email"
                           type = "email"
                        />
                     </FormField>

                     <FormField
                        required
                        label     = "Password"
                        name      = "password"
                        className = "form__input"
                     >
                        <TextInput
                           reverse
                           name = "password"
                           type = "password"
                           icon = { <Key /> }
                        />
                     </FormField>

                     <Box
                        direction = "row"
                        justify   = "between"
                        margin    = { { top: "small", left: "small" } }
                     >
                        <CheckBox
                           id    = "remember-me"
                           name  = "remember"
                           label = "Запомните меня" 
                        />
                     </Box>

                     <Box
                        direction = "row"
                        justify   = "between"
                        margin    = { { top: "medium" } }
                     >
                        <Button
                           primary
                           type    = "submit"
                           justify = "center"
                           label   = "Login" 
                        />
                     </Box>
                  </Form>
               </Box>
            </Box>
         </div>
      </div>
   );
});
