import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import { observer         } from 'mobx-react'
import { projectLinkStore } from 'store/ProjectLinkStore'

import { Tip              } from 'grommet'
import { Handle           } from 'react-flow-renderer'
import { TPinNode         } from 'models/Pin'

import { TProjectLinkItem } from 'models/ProjectLink'
import { EmulatorContext  } from 'components/Projects/ProjectDetailCard/ProjectDetailCard'
import { PinLink          } from './components/PinLink'
import { PinValue         } from './components/PinValue'
import { PinContextMenu   } from './PinContextMenu/PinContextMenu'

/* type TPinelectricalType = {
   1: "Вход",
   2: "Выход",
   3: "Вход/Выход",
   4: "Пассивный",
   5: "Питание",
   6: "Земля"
} */

type TContextPin = {
   unit: number,
   pin : number,
};

export type TStyleProps = {
   pin   : CSSProperties, 
   link? : CSSProperties,
   name? : CSSProperties,
   value?: CSSProperties,
}

const defaultStyle: TStyleProps = {
   pin: {
   },
   link: {
      position    : 'absolute',
      width       : 3,
      height      : 90,
   },
   name: {
      whiteSpace  : 'nowrap',
      zIndex      : 1,
   },
   value: {
      position    : 'absolute',
      width       : 59,
      borderRadius: '3px',
      zIndex      : 1,
   },
};

export const Pin = observer ((props: {
   position,
   device,
   pin: TPinNode,
   style,
   sice: number
}) => {

   const {
      position,
      device,
      pin,
      sice
   } = props

   let activePins   = []
   let reactivePins = []

   const emulator = useContext (EmulatorContext)
   if (emulator) {
      activePins   = emulator.activePins
      reactivePins = emulator.reactivePins
   }

   const [pinBackground  , setPinBackground  ] = useState <string> ()
   const [valueBackground, setValueBackground] = useState <string> ('background')
   const [style          , setStyle          ] = useState <TStyleProps> (defaultStyle)
   const [pinLink        , setPinLink        ] = useState <TProjectLinkItem> ()
   const [pinValue       , setPinValue       ] = useState <number> ()
   const [menu           , setMenu           ] = useState (false)
   const [contextPin     , setContextPin     ] = useState <TContextPin> ()

   const pinTargetRef = useRef (null)

   useEffect(() => {

      const pinLink = projectLinkStore.links?.find(link =>
         link.contacts.some (contact => contact.pin == pin.pin.id_unitslotpin)
      )

      setPinLink (pinLink || null)

   }, [projectLinkStore.links])

   useEffect (() => {
         
      switch (pin.pin.id_pinelectricaltype) {
         case 1:
            setPinBackground ('green') 
            break;
         case 2:
            setPinBackground ('red')
            break;
         case 3:
            setPinBackground ('blue')
            break;
         case 4:
            setPinBackground ('yellow')
            break;
         case 5:
            setPinBackground ('brown')
            break;
         case 6:
            setPinBackground ('black')
            break;
      }
   }, [pin])

   useEffect (() => {
         
      setValueBackground('background');

      const aPin = activePins  ?.find (aPin => aPin?.pin == pin.pin.id_unitslotpin)
      const rPin = reactivePins?.find (rPin => rPin?.pin == pin.pin.id_unitslotpin)

      if (aPin) setPinValue (aPin.value)
      if (aPin && aPin?.value != '0') setValueBackground ('blue')

      if (rPin) setPinValue (rPin.value)
      if (rPin && rPin?.value != '0') setValueBackground ('yellow')

   }, [pin, activePins, reactivePins])

   useEffect(() => {

      if (position == 'top') {

         setStyle({
            pin: {
               ...style.pin,
               ...props.style,
               background: pinBackground,
               width : sice,
               height: sice,
            },
            link: {
               ...style.link,
            },
            name: {
               ...style.name,
               transform: 'rotate(-90deg) translate(-40px, -12px)',
            },
            value: {
               ...style.value,
               transform: 'rotate(-90deg)',
            },
         })
      } else {

         setStyle({
            pin: {
               ...style.pin,
               ...props.style,
               background: pinBackground,
               width : sice,
               height: sice,
            },
            link: {
               ...style.link,
            },
            name: {
               ...style.name,
               transform: 'rotate(90deg) translate(20px, -12px)',
            },
            value: {
               ...style.value,
               transform: 'rotate(90deg)',
            },
         })
      }
   }, [position, pin, pinBackground, pinValue, pin.slotType])

   const pinMenu = (e, pin) => {

      e.preventDefault ()
      pinTargetRef.current = e.target

      setContextPin ({
         unit: pin.nunit,
         pin : pin,
      })
      setMenu (true)
   };

   //console.log (toJS (pin))
   //console.log(pinValue)
   //console.log('activePins: ', activePins)
   //console.log('reactivePins: ', reactivePins)
   //console.log(highlightPins)
   //console.log(contextPin)
   //console.log(toJS(pinLink))
   //console.log(style)
   //console.log (pin.slotType)

   return (
      <>
         <Tip
            /* content={`${pin.pin.npin} - ${pin.pin.typename} (${pin.pin.typedescription})\r\n
                  PORT[${pin.nunit}][${pin.nport}]`} */
            content = { `${pin.pin?.typedescription}` 
                           + (pin.nunit !== undefined 
                              ? ` - PORT[${pin.nunit}][${pin.nport}]` 
                              : '') }
         >
            <Handle
               id            = { 'pin-' + pin.pin.id_unitslotpin.toString() }
               type          = 'source'
               position      = { position                   }
               style         = { style?.pin                 }
               onContextMenu = { (e) => pinMenu(e, pin.pin) }
               //type={pin.pin.isin ? ("target") : ("source")}
            />
         </Tip>

         { pinLink && 

               <PinLink
                  pin          = { pin }
                  pinLink      = { pinLink }
                  defaultStyle = { style }
                  position     = { position }
               />
         }

         { pinValue != undefined && 

               <PinValue
                  pin             = { pin }
                  pinValue        = { pinValue }
                  valueBackground = { valueBackground }
                  defaultStyle    = { style }
                  position        = { position }
               />
         }

         { (menu       && 
            pinTargetRef.current && 
            contextPin) &&
              
                 <PinContextMenu
                    targetRef = { pinTargetRef.current }
                    device    = { device }
                    apin      = { pin }
                    setMenu   = { setMenu }
                 />
         }
      </>
   );
}
);
