import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { TLinkedHardware          } from "models/Hardware"
import { getAvailableHardwareList } from "api/hardware"
import { saveLinkedHardware       } from "api/unit"

import {
   Box,
   Button,
   CheckBox,
   Heading,
   Select,
   Table,
   TableBody,
   TableCell,
   TableRow,
   Text
} from "grommet"
import { Modal } from "components/UI/Modal/Modal"

type TChooseHardware = {
   show       : boolean,
   setShow    : React.Dispatch<React.SetStateAction<{ show: boolean }>>,
   hardware   : TLinkedHardware,
   setHardware: React.Dispatch<React.SetStateAction<TLinkedHardware>>
};

export function ChooseHardware ({
   show,
   setShow,
   hardware,
   setHardware
}: TChooseHardware) {

   const [hardwareList  , setHardwareList  ] = useState <TLinkedHardware[]>()
   const [chooseHardware, setChooseHardware] = useState <TLinkedHardware>()
   const [checked       , setChecked       ] = useState (false);
   const { idUnit } = useParams()

   const saveChooseHardware = useCallback (() => {

      saveLinkedHardware (Number (idUnit), chooseHardware.id_hardware)
      .then (response => {
         setHardware (response)
         setShow     ({ show: false })
      })
   }, [idUnit, chooseHardware])

   useEffect (() => {
      getAvailableHardwareList (Number(idUnit))
      .then (response => 
         setHardwareList (response.map (hardware => {
            if (hardware.islinked)
               return { ...hardware, name: hardware.name + ' (привязан)' }
            else return hardware
         }))
      )
   }, [idUnit, hardware])

   //console.log(chooseHardware)

   return (

      <Modal
         modal    = { { show: show } }
         setModal = { setShow }
         heading  = 'Выберите оборудование для связи'
      >
         <>

            { (hardwareList && hardwareList.length > 0)

               ? ( 
                  <Select
                     options     = { hardwareList }
                     labelKey    = 'name'
                     value       = { chooseHardware }
                     valueKey    = 'id_hardware'
                     placeholder = 'Не выбрано'
                     onChange    = { ({ option }) => setChooseHardware (option) }
                  /> 
               ) : (
                  'Подходящее оборудование не найдено'
               )
            }

            { (chooseHardware && chooseHardware?.islinked) &&

               <>
                  <Box>
                     <Heading
                        level = '4'
                        textAlign = "center"
                        fill = { true }
                     >
                        Оборудование уже привязано!
                     </Heading>
                     <Table>
                        <TableBody>
                           <TableRow>
                              <TableCell scope = "row">
                                 <strong>Комопнент</strong>
                              </TableCell>
                              <TableCell>{ chooseHardware?.unitname }</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell scope = "row">
                                 <strong>Устройство</strong>
                              </TableCell>
                              <TableCell>{ chooseHardware?.devicename }</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell scope = "row">
                                 <strong>Проект</strong>
                              </TableCell>
                              <TableCell>{ chooseHardware?.projectname }</TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </Box>

                  <Box
                     margin = { { top: 'medium' } }
                  >
                     <CheckBox
                        checked = { checked }
                        label = 'Подтвердите, что хотите отвязать оборудование от старого компонента и привязать к новому'
                        onChange = { (event) => setChecked(event.target.checked) }
                     />
                  </Box>
               </>

            }

            <Box
               as        = "footer"
               gap       = "small"
               direction = "row"
               align     = "center"
               justify   = "end"
               pad       = { { top: "medium", bottom: "small" } }
            >
               <Button
                  label   = "Отмена"
                  color   = "dark-3"
                  onClick = { () => setShow ({ show: false }) }
               />

               { (hardwareList && hardwareList.length > 0) &&

                  <Button
                     primary
                     label    = { <Text color = "white"><b>Привязать</b></Text> }
                     color    = "success"
                     onClick  = { saveChooseHardware }
                     disabled = { (!chooseHardware?.islinked || checked) ? false : true }
                  />

               }
            </Box>
         </>
      </Modal>
   );
}
