import React, { useContext, useEffect, useState } from "react"
import { useParams              } from "react-router-dom"

import { Box, Button, Menu      } from "grommet"
import { Add, Console, Previous } from "grommet-icons"
import { LinkButton             } from "components/UI/LinkButton/LinkButton"

import { ProjectContext         } from "../ProjectDetailCard"

export const ProjectPanel = () => {

   const {
      //setSaveProjectLink,
      emulateProject,
      idSchematic,
      setIdSchematic,
   } = useContext (ProjectContext)

   const { idProject } = useParams ()
   const [menuValue, setMenuValue] = useState <string> ('Визуальная схема')
   const [shemes   , setShemes   ] = useState ([])

   useEffect(() => {
    
      switch (idSchematic) {

         case 1:

            setShemes([ 
            /* { 
                  label: 'Принципиальная схема',
                  onClick: () => {
                     setIdSchematic(2)
                     setMenuValue("Принципиальная схема")
                  } 
               }, */
               { 
                  label: 'Список',               
                  onClick: () => {

                     setIdSchematic (3)
                     setMenuValue   ("Список")
                  } 
               }
            ]);

            setMenuValue('Визуальная схема')
            break;

            /* case 2:

            setShemes([ 
               { label: 'Визуальная схема', 
                  onClick: () => {
                     setIdSchematic(1)
                     setMenuValue('Визуальная схема')
                  } },
               { label: 'Список',
                  onClick: () => {
                     setIdSchematic(3)
                     setMenuValue("Список")
                  } 
               }
            ])

            setMenuValue('Принципиальная схема')
            break; */

         case 3:
            setShemes([
               { label: 'Визуальная схема', 
                  onClick: () => {

                     setIdSchematic (1)
                     setMenuValue   ('Визуальная схема')
                  } 
               },
            /* { label: 'Принципиальная схема', 
                  onClick: () => {
                     setIdSchematic(2)
                     setMenuValue("Принципиальная схема")
                  } 
               } */
            ])

            setMenuValue('Список')
            break;
      
         default:
            break;
      }

   }, [idSchematic, setIdSchematic])

   return (
      <Box
         round      = { false }
         direction  = "row"
         background = "backgroundSideBar"
         border     = { { side: "bottom" } }
         style      = { {
            position : 'absolute',
            left     : '50vw',
            transform: 'translate(-50%, 0%)',
            zIndex   : 5,
         } }
      >
         <Box
            fill
            align     = "center"
            justify   = "start"
            direction = "row"
            width     = "small"
         >
            <Button
               tip  = "Назад"
               icon = { <Previous /> }
               href = { "/projects/" }
            />
            <LinkButton
               to    = { `/projects/${idProject}/device/new` }
               state = { { 
                  name  : "", 
                  remark: "",
               } }
            >
               <Button 
                  secondary 
                  icon = { <Add /> } 
                  tip  = "Добавить устройство"
               />
            </LinkButton>

            { // For constructed links
               /* <Button
                  secondary
                  tip="Сохранить связи"
                  onClick={() => setSaveProjectLink(true)}
                  icon={<Save />}
                  margin="xsmall"
               /> */ }

            <Button
               secondary
               tip     = "Эмуляция проекта"
               onClick = { () => emulateProject () }
               icon    = { <Console /> }
               margin  = "xsmall"
            />

            { /* props.readOnly ? (
               <Button
                  tip="Разрешить редактирование"
                  onClick={() => props.setReadOnly(false)}
                  icon={<Lock />}
               />
            ) : (
               <Button
                  tip="Запретить редактирование"
                  onClick={() => props.setReadOnly(true)}
                  icon={<Unlock />}
               />
            ) */ }

            <Menu
               label = { menuValue }
               items = { shemes    }
            />

         </Box>  
      </Box>
   )
}