import React from 'react';
import { Position } from 'react-flow-renderer';

type TCLProps = {
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number,
  sourcePosition: Position,
  targetPosition: Position,
  connectionLineType,
  connectionLineStyle,
}

export const ConnectionLine =  (props: TCLProps) => {

  const {
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    connectionLineType,
    connectionLineStyle,
  } = props

  return (
    <> 
      <g></g>
      {/* <g>
        <path
          className='react-flow__edge-path animated'
          //fill="none"
          //stroke="#222"
          d={`M${sourceX},${sourceY} C ${sourceX} ${targetY} ${sourceX} ${targetY} ${targetX},${targetY}`}
        />
        <circle 
          cx={targetX} 
          cy={targetY} 
          r={10} 
          //fill="#fff" 
          //stroke="#222" 
          //strokeWidth={10} 
        />
      </g> */}
    </>
  );
};