import React, { FC } from "react";
import { observer } from "mobx-react";
import { Box, Heading } from "grommet";

export const NotifiComponent: FC = observer(() => {

   return (
      <Box
         pad = "large"
      >
         <Heading
            style = { { margin: "0" } }
         >
            Уведомления
         </Heading>
      </Box>
   )  
});
