import React, { useContext } from 'react'
import { EditorContext } from './EditorContextProvider'
import { Editor } from './Editor'
import { EditorDiff } from './DiffEditor'

export const EditorPano = () => {

   const { history } = useContext (EditorContext)

   if (history) return <EditorDiff />
   else         return <Editor />
}
