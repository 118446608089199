import React from "react";
import { observer  } from "mobx-react";

import { TInnerPort } from "models/Device";

import { Box, Text } from "grommet";
import { Loader    } from "../Common/Loader";
import { PortCard  } from "./PortCard";

export const PortTable = observer(({
   role,
   ports,
}: {
   role  : 'out' | 'in',
   ports?: TInnerPort[],
}) => {

   //console.log (ports)

   if (!ports) return <Loader size = "small" />
   else {
      return ports?.length 
      ? (

         <Box
            pad      = { { horizontal:"xsmall" } }
            gap      = "xxsmall"
            width    = "280px"
            overflow = { { vertical: "scroll", horizontal: "hidden" } }
         >

            { ports &&

               <div>

                  { ports?.map (port => 

                     <PortCard
                        port  = { port }
                        key   = { port.id_unitport }
                        name  = { port.name }
                        align = { (role === 'in' ) && { left : "right" } ||
                                  (role === 'out') && { right: "left"  } }
                     />
                  ) }

               </div> }

         </Box>

    ) : (

      <Box
         align   = "center"
         justify = "center"
         margin  = "small"
         wrap    = { false }
      >
         <Text>Нет портов</Text>
      </Box>

    )
   }

});
