import { useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { deviceStore } from 'store/DeviceStore'
import { projectLinkStore } from 'store/ProjectLinkStore'

import { toast } from 'react-toastify'

import { compileDeviceSoftware } from 'api/softwarecode'
import { TSoftwareOrder } from 'models/SoftwareCode'
import { ACCESS_TOKEN } from "constants/globalConstants"
import { projectStore } from 'store/ProjectsStore'
import { TPinValue } from '../ProjectDetailCard'

export type TActivPort = {
   source?: MessageEventSource,
   origin?: WindowPostMessageOptions,
   device: number,
   nunit : number | null,
   nport : number,
   value : number,
};

export type TActivPin = {
   device: number,
   pin   : number,
   value : number,
};

export type TScreen = {
   device: number,
   value : string,
}

const ports   = {}
const emPath  = {}
let devices   = {}
const initial = []

export const useProjectEmulator = ({
   idProject,
   pinValues
} : {
   idProject: number,
   pinValues: TPinValue[]
}) => {

   const navigate = useNavigate ()

   const [isEmulate     , setIsEmulate     ] = useState <boolean> (false)
   const [emulateLoading, setEmulateLoading] = useState <boolean> (false)
   const [emulateDevice , setEmulateDevice ] = useState <number> ()

   const [source        , setSource        ] = useState <MessageEvent> ()

   const [activePort    , setActivePort    ] = useState <TActivPort> ()
   const [activePins    , setActivePins    ] = useState <TActivPin[]> (initial)
   const [reactivePins  , setRectivePins   ] = useState <TActivPin[]> (initial)
   const [screens       , setScreens       ] = useState <TScreen[]>   (initial)

   useEffect(() => {

      if (idProject) {

         deviceStore.getDevices (idProject)
         .then (() => {

            devices = deviceStore.devices.reduce ((acc, device) => {

               acc[device?.id_device] = {
                  name         : device?.name,
                  isexternal   : device?.units?.some   (unit => !unit.isinternal),
                  inDigitPort  : device?.ports?.filter (port => port.isdigital  && port.isin),
                  outDigitPort : device?.ports?.filter (port => port.isdigital  && !port.isin),
                  inAnalogPort : device?.ports?.filter (port => !port.isdigital && port.isin),
                  outAnalogPort: device?.ports?.filter (port => !port.isdigital && !port.isin),
               }

               return acc
            }, {})
         })
      }
   }, [idProject])

   const emulateProject = async () => {

      setEmulateLoading (true)
      let error = false

      for (const device of deviceStore.devices.values ()) {

         if (device.units.some (unit => unit.id_unittypegroup == 1)) {

            if (device.softwarecode == '' || device.softwarecode == null) {

               toast.error (
                  'Устройство "' +
                  device.name    +
                  '" не содержит исполняемого кода'
               );
            }
            else {

               setEmulateDevice (device.id_device)

               device.ports.forEach (port =>

                  ports[port?.nunit] = device?.ports
                  .filter (aport => aport.nunit == port.nunit)
                  .reduce ((obj, aport) => {
                     return {
                        ...obj,
                        [aport.nport]: {
                           IsDigital: aport.isdigital,
                           IsIn     : aport.isin,
                        },
                     }
                  }, {})
               );

               const orderData: TSoftwareOrder = {
                  id_device   : device.id_device,
                  id_softwarecode: device.id_softwarecode,
                  softwarecode: device.softwarecode.replace (/\r/g, ''),
                  ports       : JSON.stringify (ports),
                  mode        : 'em',
               };

               await compileDeviceSoftware (
                  orderData.id_device,
                  orderData.id_softwarecode,
                  orderData.softwarecode,
                  orderData.ports,
                  orderData.mode,
               )
               .then (response => {

                  if (response) {

                     if (response.error_text) {

                        if (response.error_text.includes ('Connection refused')) {
                           toast.error ('Сервис компиляции недоступен\r\nПовторите попытку позже')
                        }
                        else {

                           toast.error (
                              'Устройство ' +
                              orderData.id_device +
                              ': ' + response.error_text
                           )
                        }

                        error = true
                     }
                     else {

                        if (response?.result?.code == 0) emPath[orderData.id_device] = response.out_path
                        else {

                           toast.error ('Ошибка компиляции кода устройства "' + device.name + '"')

                           if (response.out_path) {

                              navigate (`/projects/${idProject}/device/${orderData.id_device}/code?error=${response.out_path}`)
                           }
                           else {

                              toast.error (
                                 'Ошибка получения логов компиляции кода устройства "' +
                                 device.name + '"'
                              )
                           }

                           error = true
                        }
                     }
                  }
                  else {
                     toast.error ('Ошибка обращения к сервису эмуляции')
                     error = true
                  }
               });
            }
         }

         setEmulateLoading (false)

         if (error) break
      }

      if (!error) {

         window.open ('https://kraycom.ru/Emulator/index.html')
         window.addEventListener ('message', receiveMessage)
      }
   };

   const receiveMessage = useCallback (event => {

      const obj = JSON.parse (JSON.stringify ({
         action    : 'project',
         id_project: idProject,
         name      : projectStore.projects?.find (project => project.id_project == idProject).name,
         paths     : emPath,
         devices,
         auth      : JSON.parse (localStorage.getItem (ACCESS_TOKEN))
      }))

      if (event.origin == 'https://kraycom.ru') {

         //console.log(event.data)

         switch (event.data.action) {

            case 'init':
               if (Object.keys (devices).length) {

                  setSource (event)
                  event.source.postMessage (obj, event.origin)
                  setIsEmulate (true)
               }
               break

            case 'terminate':
               setIsEmulate (false)
               /* event.data.terminate.forEach((v, i) =>

                     deleteDeviceSoftwareFile(Number(i), v)
                     .then((response) => {
                        if (response?.error_text) toast.error('Ошибка удаления файлов эмуляции');
                     })
                  ); */
               break

            case 'emulate':
               setIsEmulate (true)
               break

            case 'message':

               setActivePort ({
                  origin: event.origin,
                  source: event.source,
                  device: event.data.device,
                  nunit : event.data.nunit,
                  nport : event.data.nport,
                  value : event.data.value,
               })
               break

            case 'wlog':

               setScreens ([{
                  device: event.data.device,
                  value : event.data.value,
               }])
               break

            default:
               //console.log(event.data)
               break
         }
      }
   },[idProject])

   useEffect (() => {

      if (pinValues && source) {

         const message = {
            action: 'active',
            pinValues,
         };

         // @ts-ignore
         source.source?.postMessage (message, source.origin);
      }
   }, [pinValues]);

   useEffect (() => {

      let aPins: TActivPin[] = activePins
      let rPins: TActivPin[] = []

      if (activePort) {

         //console.log(activePort)

         const device = deviceStore.devices?.find (device => 
            device.id_device == activePort.device
         )

         if (device) {

            let aPin: TActivPin

            const port = device.ports?.find (port =>
               port.nunit == activePort?.nunit &&
               port.nport == activePort?.nport
            )

            device.units?.flatMap (unit => unit.slots)?.forEach (slot => {

               const activePin = slot.pins.find (pin => 
                  pin.id_unitslotpin === port?.id_unitslotpin
               )

               if (activePin) aPin = {
                  device: device.id_device,
                  pin   : activePin.id_unitslotpin,
                  value : activePort.value,
               }
            })

            // Add active Pin, if he not on the list of active Pins
            if (!aPins.some ((pin) => pin?.pin == aPin?.pin)) {

               aPins = [...aPins, { ...aPin, value: activePort.value }];
            }
            // else change its value
            else {

               aPins = aPins.map (sPin => {
                  if (sPin?.pin == aPin?.pin) return aPin
                  else                        return sPin
               })
            }

            setActivePins (aPins)

            // if the project has links
            if (projectLinkStore.links) {

               const aLink = projectLinkStore.links?.find (link => link?.contacts.some (cnt => cnt.pin === aPin?.pin))

               if (aLink) {
                  aPins = aPins
                  .filter (aPin => !rPins.some (rPin => rPin.pin === aPin.pin))

                  rPins = aLink.contacts
                  .map (cnt => ({ ...cnt, value: aPin.value }))
               }

               const rPorts: TActivPort[] = rPins?.map ((rPin: TActivPin) => {

                  const port = deviceStore?.devices
                  .find (device => device.id_device    == rPin.device)?.ports
                  .find (port   => port.id_unitslotpin == rPin.pin)

                  if (port) {

                     return ({
                        device: rPin.device,
                        nunit : port.nunit,
                        nport : port.nport,
                        value : rPin.value,
                     })
                  }
               })

               activePort?.source.postMessage ({
                  action: 'reactive', 
                  ports: rPorts
               },
               activePort.origin)

               reactivePins.forEach (reactivePin => {

                  const rPinIndex = rPins.findIndex (rPin => rPin.pin == reactivePin.pin)

                  rPinIndex === -1
                  ? rPins            = [...rPins, { ...reactivePin, value: reactivePin.value }]
                  : rPins[rPinIndex] = { ...reactivePin, value: rPins[rPinIndex].value }
               })

               setActivePins  (aPins)
               setRectivePins (rPins)
            }
         }
      }
   }, [activePort]);

   useEffect(() => {

      if (!isEmulate) {

         setActivePins  (initial)
         setRectivePins (initial)
         setScreens     (initial)
      }
   }, [isEmulate])

   //console.log(localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY))
   //console.log('activePins: '   , JSON.parse(JSON.stringify(activePins  )))
   //console.log('pinValues: '    , JSON.parse(JSON.stringify(pinValues   )))
   //console.log('reactivePins: ' , JSON.parse(JSON.stringify(reactivePins)))
   //console.log (isEmulate)

   return {
      emulateProject,
      emulateLoading,
      isEmulate,
      emulateDevice,
      activePins,
      reactivePins,
      screens,
   }
}
