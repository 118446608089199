import { TDevice } from "models/Device";
import { TExternalUnitsProps } from "./types";

export const screenIonizer = (device: TDevice, slotImage): TExternalUnitsProps => {

   let pinY;
   
   const unitStyle = {
      position : 'absolute',
      top      : '165',
      left     : '130',
   };

   const slotPosition = slotImage.transform?.match (/translate \(\d+\.?\d+?, (\d+)\)/i)
   const pinPosition  = slotImage.image.match      (/cy="(\d*\.?\d+?)"/i)

   if (slotPosition) pinY = Number (slotPosition[1]) + Number (pinPosition[1]) + 206

   const deviceSize = 0

   return { pinY, unitStyle, deviceSize }
}