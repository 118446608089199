import React from "react";

import { 
   Box,
   Layer,
   ThemeContext
} from "grommet";
import { Loader } from "components/Common/Loader";

export const EmulateLoader = (props:{device: number}) => {
   return (
      <>
         <ThemeContext.Extend
            value = { {
               layer: {
                  overlay: {
                     background: "rgb(0 0 0 / 90%)"
                  }
               }
            } }
         >
            <Layer 
               background = "none"
            >
               <Loader />
               <Box 
                  alignSelf = "center"
                  style     = { { color: "white" } }
                  pad       = "medium"
               >
                  Compiling device { props.device } in progress. Wait...
               </Box>
            </Layer>
         </ThemeContext.Extend>
      </>
      
   )
}