import React, { FC, useContext } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { rootStore } from "store/RootStore";

import { Button, DropButton, Box, ResponsiveContext } from "grommet";
import { Notification, Logout } from "grommet-icons";

import { MainSideBarButton } from "../MainSideBarButton";
import { SideBarLink } from "../styles";

import Money from "assets/img/Money.svg";
import User from "assets/img/User.svg";


export const MainUserSection: FC = observer(() => {

   const navigate = useNavigate ()
   const size     = useContext  (ResponsiveContext)

   const handleLogout = () => {

      rootStore.authStore.logout();
      rootStore.currentUserStore.clearCurrentUserData();
      navigate("/login");
   };

   return (
      <>
         <Button
            secondary
            icon    = { <Notification /> }
            onClick = { () => navigate("/notifications") }
         />
         <Button
            secondary
            icon    = { <img src = { Money }/> }
            label   = { `375 ${(size === 'large') ? ' руб' : ''}` }
            onClick = { () => navigate("/billing") }
            margin  = "small"
         />

         { /* <Menu 
            label     = "Админ"
            dropAlign = { { top: "bottom" } }
            items     = { [
               {
                  label: "Настройки",
                  href: "/setting"
               },
               {
                  label: 
                     <MainSideBarButton
                        icon     = { <Logout /> }
                        name     = { "Выход" }
                        callback = { handleLogout }
                     />
               },
            ] }
         /> */ }

         <DropButton
            icon        = { <img src = { User }/> }
            margin      = "small"
            label       = { rootStore.currentUserStore.currentUserData?.name ?? "" }
            dropAlign   = { { "top": "bottom" } }
            dropContent = { (
               <>
                  <Box
                     pad = "medium"
                  >
                     <SideBarLink 
                        to = "/setting"
                     >
                        Настройки
                     </SideBarLink>
                  </Box>

                  <Box 
                     pad = "medium"
                  >
                     <MainSideBarButton
                        icon     = { <Logout />   }
                        name     = { "Выход"      }
                        callback = { handleLogout }
                     />
                  </Box>
               </> 
            ) }
         />
      </>
   );
});