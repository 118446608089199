import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Button, TextInput, Tip } from "grommet";
import { Edit, Save } from "grommet-icons";
import { observer } from "mobx-react";

export const EditableButton = observer ((props: {
   label
   tip
   onClick
   value,
   saveValue,
   style?
   align?
}) => {

   const [editButton, setEditButton] = useState (false)
   const [editInput , setEditInput ] = useState (false)

   const [value     , setValue     ] = useState (props.value)

   const buttonRef = useRef (null)
   const inputRef  = useRef (null)

   const hideControl = useCallback (() => {
      setEditInput  (false)
      setEditButton (false)
   }, [])

   const closeClick = useCallback (e => {

      if (e.target !== inputRef.current &&
          e.target !== buttonRef.current) {

         hideControl ()
         document.removeEventListener ("click"  , closeClick);
      }
   }, [])

   const closePress = useCallback (e => {

      if (e.key === 'Escape') {
         hideControl ()
      }

      if (e.key === 'Enter') {
         props.saveValue (value)
         hideControl ()
      }
   }, [value])

   useEffect(() => {

      if (editInput) {
         document.addEventListener ("keydown", closePress);
         document.addEventListener ("click"  , closeClick);
   
         return () => {
            document.removeEventListener ("keydown", closePress);
            document.removeEventListener ("click"  , closeClick);
         };
      }
   }, [editInput, closePress]);

   //console.log (props.style.align);

   return (
      <>
         { editInput 
            ? (
               <Box
                  direction = "row"
               >
                  <TextInput 
                     defaultValue = { props.value }
                     onChange     = { e => setValue (e.target.value) }
                     ref          = { inputRef }
                  />
                  <Button
                     secondary
                     fill    = { false }
                     icon    = { <Save color = 'brand' /> }
                     style   = { { 
                        padding   : '6px',
                        background: 'white',
                     } }
                     onClick = { () => { 
                        setEditInput (false)
                        props.saveValue (value)
                     } }
                  />
               </Box>
            )
            : (
               <Box
                  direction = "row"
                  onMouseEnter = { () => setEditButton (true) }
                  onMouseLeave = { () => setEditButton (false) }
                  ref          = { buttonRef }
               >
                  <Tip
                     dropProps = { {
                        align: props.align,
                        margin: { horizontal: "large" }
                     } }
                     content = { props.tip }
                  >
                     <Button
                        primary
                        fill    = "horizontal"
                        style   = { {  position: 'relative', ...props.style } }
                        label   = { props.label }
                        //tip     = { props.tip }
                        onClick = { props.onClick }
                     />
                  </Tip>
                  
                  { editButton &&
                     <Button
                        secondary
                        fill    = { false }
                        icon    = { <Edit color = 'brand' /> }
                        style   = { { 
                           padding   : '6px',
                           right     : '5px',
                           top       : '0',
                           bottom    : '0',
                           margin    : 'auto 0',
                           zIndex    : '1000',
                           background: 'white',
                           boxShadow : '0 0 5px 5px white',
                        } }
                        onClick = { () => {
                           setEditInput (true)
                        } }
                     />
                  }
               </Box>
            )
         }
      </>
   )
})