import React, { useState } from "react"

import { useLocation            } from "react-router-dom"
import { useNavigate, useParams } from "react-router-dom"
import { projectStore           } from "store/ProjectsStore"

import {
   addProjectItem,
   editProjectItem
} from "api/project"
import { TFormAddProject        } from "models/Project"

import {
   Heading,
   Main,
   TextInput,
   Button,
   Box,
   Form,
   FormField,
} from "grommet"
import { LinkButton             } from "components/UI/LinkButton/LinkButton"
import { toast                  } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

type ParamTypes = Partial<Record<"idProject", string>>

export const ProjectForm = () => {

   const navigate          = useNavigate ()
   const location          = useLocation ()
   const initialFormValue  = location.state as TFormAddProject
   const [value, setValue] = useState <TFormAddProject | undefined> (
      initialFormValue
   )

   const { idProject }     = useParams <ParamTypes> ()
   const numberId          = Number (idProject)

   const handleAddProject = (formValue: TFormAddProject) => {

      addProjectItem (formValue)
      .then (response => {

         if (response.id_project) {

            projectStore.getProjects ()
            toast.success ("Проект создан")
            navigate (`/projects/${response.id_project}`)
         } else toast.error ("Произошла ошибка при добавлении проекта")
      })
   }

   const handleEditProject = (formValue: TFormAddProject) => {

      editProjectItem ({
         id_project: numberId,
         name      : formValue.name,
         remark    : formValue.remark,
      })
      .then(response => {

         if (response.id_project) {

            projectStore.getProjects ()
            toast.success ("Проект успешно изменен")
            navigate (`/projects/${response.id_project}`)
         } else toast.error("Произошла ошибка при редактировании проекта")
      })
   }

   return (
      <Main pad = "large">
         <Heading>
            { numberId
              ? `Редактировать проект "${initialFormValue.name}"`
              : "Создать новый проект" }
         </Heading>

         <Box
            fill
            align   = "start"
            justify = "start"
         >
            <Box width = "large">
               <Form
                  value    = { value }
                  validate = "submit"
                  onChange = { nextValue => setValue (nextValue) }
                  onSubmit = { ({ value }) =>
                     numberId
                     ? handleEditProject (value)
                     : handleAddProject (value) }
               >
                  <FormField
                     name    = "name"
                     htmlFor = "text-input-name"
                     label   = "Название"
                     required
                  >
                     <TextInput
                        id   = "text-input-name"
                        name = "name" 
                     />
                  </FormField>

                  <FormField
                     name    = "remark"
                     htmlFor = "text-input-remark"
                     label   = "Описание"
                  >
                     <TextInput
                        id   = "text-input-remark"
                        name = "remark"
                     />
                  </FormField>

                  <Box
                     direction = "row"
                     gap       = "medium"
                     className = "product-form-aria-button"
                  >
                     <Button
                        primary
                        type  = "submit"
                        label = { numberId ? "Сохранить" : "Создать проект" }
                     />
                     <LinkButton
                        to = { numberId ? `/projects/${numberId}` : "/projects/" }
                     >
                        <Button
                           type  = "button"
                           label = "Отмена"
                           color = "brand"
                        />
                     </LinkButton>
                  </Box>
               </Form>
            </Box>
         </Box>
      </Main>
   )
}
