import React, { useEffect, useState } from "react";

import { Box, Button, Drop } from "grommet";

import { Edge, XYPosition } from "react-flow-renderer";
import { generateUID } from "utils/generateUID";
import { TCoord } from "./CustomPath";

const CMStyle = {
  hr: {
    margin: "0.5rem 0"
  }
}

const markerSize = 50;

export const PathContextMenu = (props: { 
  targetRef,
  position: TCoord, 
  setPathColor, 
  setPathStroke, 
  markerPosition,
  parsePath,
  reactFlowInstance,
  reactFlowWrapper,
  path,
  source,
  target,  
  id_edge
}) => {

  const [colorMenu, setColorMenu] = useState(false)
  const [strokeMenu, setStrokeMenu] = useState(false)
  const [subMenuPosition, setSubMenuPosition] = useState<TCoord>()

  const deleteEdge = () => {

    props.reactFlowInstance.setEdges([...props.reactFlowInstance.getEdges().filter(eds => eds.id != props.id_edge)])
  }

  const addMarker = () => {

    const id_marker = props.reactFlowInstance.getNodes().filter(mrk => mrk.type == 'markerNode').length;
    const markerPosition: XYPosition = props.markerPosition(props.position);
    const sourcePath: TCoord[] = [];
    const targetPath: TCoord[] = [];

    props.reactFlowInstance.addNodes({
      id: `marker-${id_marker}`,
      type: "markerNode",
      position: {
        x: markerPosition.x - markerSize / 2,
        y: markerPosition.y - markerSize / 2
      }
    })

    const onVector = (coord1: TCoord, coord2: TCoord, point: TCoord) => {

      if (coord1.y == coord2.y) {

        if (coord2.x > coord1.x) return coord1.x < point.x && point.x < coord2.x;
        else                     return coord2.x < point.x && point.x < coord1.x;
      }
      else {

        if (coord2.y > coord1.y) return coord1.y < point.y && point.y < coord2.y;
        else                     return coord2.y < point.y && point.y < coord1.y;
      }
    }

    props.path.forEach((pathPoint: TCoord, i) => {

      if (pathPoint.x == markerPosition.x || pathPoint.y == markerPosition.y) {
        
        if (onVector(pathPoint,
                     props.path[i + 1],
                     markerPosition)) {

          sourcePath.push(pathPoint)
          sourcePath.push(markerPosition)
        }
        else {

          targetPath.push(markerPosition)
          targetPath.push(pathPoint)
        }
      }
      else {

        if (targetPath.length) targetPath.push(pathPoint)
        else                   sourcePath.push(pathPoint)
      }
    });

    //console.log(props.source)
    //console.log(props.target)
    //console.log(markerPosition)
    //console.log(sourcePath)
    //console.log(targetPath)

    const sourceEdge: Edge = props.reactFlowInstance.getEdges().find(eds => eds.id == props.id_edge);

    deleteEdge();

    props.reactFlowInstance.addEdges({ id: "edge-" + generateUID(),
                                       type: "customEdge", 
                                       source: sourceEdge.source, 
                                       sourceHandle: sourceEdge.sourceHandle, 
                                       target: "marker-" + id_marker, 
                                       targetHandle: "marker-" + id_marker,
                                       data: {reactFlowInstance: props.reactFlowInstance,
                                              reactFlowWrapper: props.reactFlowWrapper,
                                              path: sourcePath} })

    props.reactFlowInstance.addEdges({ id: "edge-" + generateUID(),
                                       type: "customEdge", 
                                       source: "marker-" + id_marker, 
                                       sourceHandle: "marker-" + id_marker, 
                                       target: sourceEdge.target, 
                                       targetHandle: sourceEdge.targetHandle,
                                       data: {reactFlowInstance: props.reactFlowInstance,
                                              reactFlowWrapper: props.reactFlowWrapper,
                                              path: targetPath} })
                          
  }

  //console.log(props.reactFlowInstance.getEdges())
  //console.log(props.reactFlowInstance.getNodes())
  //console.log(props.path)

  return (
    <>
      <Drop
        elevation="medium"
        style={{ 
          position: "absolute",
          top:  props.position.y,
          left: props.position.x
        }}
        target={props.targetRef}
      >
        <Box
          pad="small"
        >
          <Button
            plain
            label="Создать точку"
            onClick={() => addMarker()}
            onMouseOver={(e) => {
              setStrokeMenu(false)
              setColorMenu(false)
            }}
          />
          <hr style={CMStyle.hr}/>
          <Box
            style={{ 
              cursor: "pointer",
            }}
            onMouseOver={(e) => {
              setSubMenuPosition({
                x: e.clientX,
                y: e.clientY
              })
              setColorMenu(true)
              setStrokeMenu(false)
            }}
          >
            Цвет
          </Box>
          <hr style={CMStyle.hr} />
          <Box
            style={{ 
              cursor: "pointer",
            }}
            onMouseOver={(e) => {
              setSubMenuPosition({
                x: e.clientX,
                y: e.clientY
              })
              setStrokeMenu(true)
              setColorMenu(false)
            }}
            
          >
            Толщина
          </Box>
          <hr style={CMStyle.hr} />
          <Button
            plain
            label="Автопуть"
            onClick={() => props.parsePath()}
            onMouseOver={(e) => {
              setStrokeMenu(false)
              setColorMenu(false)
            }}
          />
          <hr style={CMStyle.hr} />
          <Button
            plain
            label="Удалить"
            onClick={() => deleteEdge()}
            onMouseOver={(e) => {
              setStrokeMenu(false)
              setColorMenu(false)
            }}
          />
        </Box>
      </Drop>
      {colorMenu &&
        <Drop
          elevation="medium"
          style={{ 
            position: "absolute",
            top:  subMenuPosition?.y,
            left: subMenuPosition?.x
          }}
        >
          <Box pad="small" >
            <Button
              plain
              label="Жёлтый"
              onClick={() => props.setPathColor("yellow")}
            />
            <hr style={CMStyle.hr} />
            <Button
              plain
              label="Зелёный"
              onClick={() => props.setPathColor("green")}
            />
            <hr style={CMStyle.hr} />
            <Button
              plain
              label="Красный"
              onClick={() => props.setPathColor("red")}
            />
          </Box>
        </Drop>
      }
      {strokeMenu &&
        <Drop
          elevation="medium"
          style={{ 
            position: "absolute",
            top:  subMenuPosition?.y,
            left: subMenuPosition?.x
          }}
        >
          <Box pad="small" >
            <Button
              plain
              label="5px"
              onClick={() => props.setPathStroke("5px")}
            />
            <hr style={CMStyle.hr} />
            <Button
              plain
              label="10px"
              onClick={() => props.setPathStroke("10px")}
            />
            <hr style={CMStyle.hr} />
            <Button
              plain
              label="15px"
              onClick={() => props.setPathStroke("15px")}
            />
          </Box>
        </Drop>
      }
    </>
  )
}