import { makeAutoObservable } from "mobx";
import { errorHandler } from "../utils/errorHandler";
import { THardware } from "models/Hardware";
import { getHardwareList } from "api/hardware";

class HardwaresStore {

   loading = false;
   hardwares: THardware[] = [];

   constructor () {

      makeAutoObservable(this);
   }

   async getHardwares () {

      try {
         this.setLoading (true);
         const result = await getHardwareList ();
         this.setHardwares (result);
      }
      catch (err) {

         errorHandler (err);
      }
   }

   setHardwares (hardwaresArr: THardware[]) {
      this.hardwares = hardwaresArr;
      this.setLoading(false);
   }

   setLoading = (isLoading: boolean) => {
      this.loading = isLoading;
   };
}

export const hardwareStore = new HardwaresStore ();