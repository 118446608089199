import { useState, useEffect } from 'react'

type TResult<T> = {
   data   : T | null,
   loading: boolean,
}

export const useAsyncFn = <T>(
   apiFunc: (param: number | string) => Promise<T>,
   param  : number | string
): TResult<T> => {

   const [data   , setData   ] = useState <T | null> (null)
   const [loading, setLoading] = useState <boolean>  (true)
 
   useEffect(() => {

      const fetchData = async () => {
         setLoading (true) 

         try {
            const result = await apiFunc (param)
            setData (result)
         } finally {
            setLoading (false)
         }
      }
 
      fetchData ()
   }, [apiFunc, param])
 
   return { data, loading }
}