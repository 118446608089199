import React, { useEffect, useState } from "react";

import { observer } from "mobx-react";

import { TSlot } from "../../models/Slot";
import { TPinItem } from "models/Pin";
import { deviceLinkStore } from "store/DeviceLinkStore";
import { slotStore } from "store/SlotStore";

import { Grid, Box, Text, Button, Layer, Tip } from "grommet";
import { Add, Subtract } from "grommet-icons";

import { Loader } from "../Common/Loader";

import { getAvaliableSlotsForLink } from "./Components/LinkManage";
import { LinkDelete } from "./Components/LinkDelete";
import { UnitCreate } from "components/Units/Components/UnitCreate";
import { LinkCreate } from "./Components/LinkCreate";

import { getPinList } from "api/pin";

export const SlotCardHorizontal = observer ((props: {

   slot    : TSlot, 
   idUnit  : number,
   isOuter?: boolean,
   deleteLink? 
   
}) => {

   const [showCreateLink, setShowCreateLink] = useState<boolean>()
   const [showDeleteLink, setShowDeleteLink] = useState<boolean>()
   const [ref , setRef ] = useState<number>(0)
   const [pins, setPins] = useState<TPinItem[]>([])

   useEffect(() => {

      if (props.isOuter) {

         getPinList (Number (props.slot.id_unitslot))
         .then (pins => {if (pins != undefined) setPins (pins)})
      }
   }, []);

   useEffect(() => {!showCreateLink ? setRef(0) : {}}, [showCreateLink])

   const findName = () => {

      let FindSlot: TSlot;

      const linkSlots = deviceLinkStore.links?.find(link => link.id_slotlink === props.slot.id_slotlink);

      const pairingSlot: number = (props.slot.id_unitslot == linkSlots?.id_unitslots[0])
      ? linkSlots?.id_unitslots[1]
      : linkSlots?.id_unitslots[0];
      
      const JSlots = Object.values(slotStore.slots)

      for (let i = 0; i < JSlots.length; i++) {

         const unit = JSlots[i];
         const findSlot: TSlot = unit?.find(slot => slot.id_unitslot === pairingSlot);

         if (findSlot?.name) {
            FindSlot = findSlot;
            break;
         }
      }

      return FindSlot?.name || '';
   }

   if (deviceLinkStore.loading || slotStore.loading) {
      return <Loader size = "small" />;
   } else {

      return (

         <Box
            margin     = { { vertical: "2px" } }
            background = { props.slot.id_slotlink != null
                           ? "backgroundSideBar"
                           : "baseBackground" }
         >

            { props.slot.id_slotlink == null && (

               <Grid
                  key     = { props.slot.id_unitslot }
                  pad     = "xxsmall"
                  gap     = "xxsmall"
                  columns = { props.isOuter ? [] : ["3/4", "1/4"] }
               >

                  { showCreateLink && (

                     <Layer
                        onEsc          = { () => setShowCreateLink (false) }
                        onClickOutside = { () => setShowCreateLink (false) }
                     >

                        { !getAvaliableSlotsForLink(props.slot.id_unitslot) &&

                           <UnitCreate
                              setShow = { setShowCreateLink }
                              slot    = { props.slot }
                           />
                        }

                        { getAvaliableSlotsForLink (props.slot.id_unitslot) &&

                           <>

                              { ref == 0 &&

                                 <Box margin = "medium">
                                    <Box pad = "medium">
                                       <Button
                                          primary
                                          gap     = "small"
                                          label   = "Создать новый компонет"
                                          onClick = { () => setRef (1) }
                                       />
                                    </Box>
                                    <Box pad = "medium">
                                       <Button
                                          primary
                                          gap     = "small"
                                          label   = "Установить связь с существующим"
                                          onClick = { () => setRef (2) }
                                       />
                                    </Box>
                                 </Box>
                              }

                              { ref == 1 &&

                                 <UnitCreate
                                    slot    = { props.slot }
                                    setShow = { setShowCreateLink }
                                 />
                              }

                              { ref == 2 &&

                                 <LinkCreate
                                    slot    = { props.slot }
                                    setShow = { setShowCreateLink } 
                                 /> 
                              }
                        
                           </> 
                        }

                     </Layer>
                  ) }

                  <Box
                     direction = "row"
                     align     = "left"
                     alignSelf = "center"
                  >
                     <Text size = "inter">

                        { props.slot.name + " " }
                        { props.isOuter &&

                           <span>

                              { pins.length != 0 && "[" }

                              { pins.map ((pin: TPinItem, index) => (

                                 <Tip
                                    content = { `${pin.npin} - ${pin.typename} (${pin.typedescription})` }
                                    key     = { pin.npin }
                                 >
                                    { index ? ", " + pin.npin : pin.npin }
                                 </Tip>
                              )) }

                              { pins.length != 0 && "]" }

                           </span>
                        }
                     </Text>
                  </Box>

                  { !props.isOuter &&
                  
                     <Box background = { "none" }>
                        <Button
                           primary
                           icon  = { <Add /> }
                           size  = "small"
                           style = { {
                              borderRadius: "9px",
                              padding     : "6px",
                              width       : "fit-content",
                           } }
                           alignSelf = "center"
                           onClick   = { () => setShowCreateLink (true) }
                        />
                     </Box>
                  }
            
               </Grid>
            ) }

            { props.slot.id_slotlink != null && (

               <Grid
                  key     = { props.slot.id_unitslot }
                  pad     = "xxsmall"
                  gap     = "xxsmall"
                  columns = { ["3/4", "1/4"] }
               >

                  <Box
                     direction = "row"
                     align     = "center"
                     height    = "36px"
                  >
                     <Text size = "inter">
                        { props.slot.name + " -> " + findName () }
                     </Text>
                  </Box>

                  <Box background = { "none" }>

                     <Button
                        primary
                        icon  = { <Subtract /> }
                        size  = "small"
                        style = { {
                           borderRadius: "9px",
                           padding: "6px",
                           width: "fit-content",
                        } }
                        alignSelf = "center"
                        onClick   = { () => setShowDeleteLink (true) }
                     />

                  </Box>
               </Grid>
            ) }

            { showDeleteLink &&

               <LinkDelete
                  setShow = { setShowDeleteLink }
                  slot    = { props.slot }
                  idUnit  = { props.idUnit }
               />
            }
         </Box>
      )
   }
});
