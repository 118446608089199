import React, { useState } from "react";

import { Handle, Position } from "react-flow-renderer";
import { Box } from "grommet";

export const DummyNode = ({ id } : { id: string }) => {

   const [nodeBody, setNodeBody] = useState<boolean> (false)

   return (
      <Box
         border = { nodeBody ? { size: 'medium' } : false }
         style  = { { 
            minWidth    : "50px",
            minHeight   : "50px",
            borderRadius: "5px",
         } }
         onMouseEnter = { () => setNodeBody(true) }
         onMouseLeave = { () => setNodeBody(false) }
      >
         <Handle
            id       = { id }
            type     = "source" 
            position = { Position.Bottom } 
            style    = { { 
               top       : 15,
               left      : 25,
               background: "blue",
               width     : 20,
               height    : 20,
               border    : "none",
            } } 
         />
      </Box>
   )
}