import React, { useContext, useEffect, useState } from "react"
import { observer } from "mobx-react"

import { Box, Button, Text } from "grommet"
import { PortTable } from "components/Ports/PortsTable"
import { CaretLeftFill, CaretRightFill } from "grommet-icons"
import { TInnerPort } from "models/Device"
import { EditorContext } from "../EditorContextProvider"

export const PortPanel = observer(({
   role,
   ports,
}: {
   role  : 'out' | 'in',
   ports?: TInnerPort[],
}) => {

   const { openPanels } = useContext (EditorContext)
   const [open, setOpen] = useState <boolean> (openPanels)
   useEffect (() => setOpen (openPanels), [openPanels])

   return (
      <>
         { open 
         ? (

            <Box
               margin = { { vertical:"medium" } }
               border = { true }
               round  = { role === 'out'
                        ? { size: "xsmall", corner: "left" }
                        : { size: "xsmall", corner: "right" } }
            >
               <Box
                  style     = { { /*display: "block",*/ minHeight: "34px" } }
                  direction = "row"
               >
                  <Button
                     tip       = "Свернуть"
                     onClick   = { () => setOpen (!open) }
                     icon      = { role === 'out' ? <CaretRightFill /> : <CaretLeftFill/> }
                     style     = { { padding: "5px" } }
                     alignSelf = "start"
                  />

                  { role === 'out' && <Text alignSelf = "center">Выходы:</Text> }
                  { role === 'in'  && <Text alignSelf = "center">Входы: </Text> }

               </Box>

               <PortTable
                  role  = { role }
                  ports = { ports }
               />
            </Box>
         ) : (

            <Box
               margin = { { vertical:"medium" } }
               border = { true }
               round  = { role === 'out' 
                        ? { size: "xsmall", corner: "left" }
                        : { size: "xsmall", corner: "right" } }
               align  = "center"
            >
               <Button
                  tip     = "Развернуть"
                  onClick = { () => setOpen(!open) }
                  icon    = { role === 'out' ? <CaretLeftFill /> : <CaretRightFill/> }
                  style   = { { padding: "5px" } }
               />
            </Box>
         ) }
      </>
   )
})
