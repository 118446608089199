import React from "react";

import { editHardwareDescription } from "api/hardware"
import { EditableBox             } from "components/UI/EditableBox/EditableBox"
import { hardwareStore           } from "store/HardwareStore"

export const ResultDescription = ({ hardware, description }) => {

   const saveValue = value => {

      editHardwareDescription (hardware, value != '' ? value : description)
      .then(() => hardwareStore.getHardwares ());
   }

   return (
      <EditableBox
         key       = { hardware }
         value     = { description }
         saveValue = { saveValue }
      />
   )
} 